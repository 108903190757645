import React, { useEffect } from 'react';
import {
  gql,
  useQuery,
  useMutation,
} from '@apollo/client';
import {
  useForm,
} from 'react-hook-form';
import {
  Intent,
} from '@blueprintjs/core';
import {
  useNavigate,
} from 'react-router-dom';

import toaster from 'helpers/toaster';
import FormButtons from 'components/FormButtons';
import ProjectFormFields from 'components/Project/ProjectFormFields';
import Spinner from 'components/Spinner';

import styles from './index.module.css';

const GET_NEXT_AVAILABLE_PROJECT_IDENTIFIER = gql`
  query GetNextAvailableProjectIdentifier {
    nextAvailableIdentifier: projectGetNextAvailableIdentifier
  }
`;

const CREATE_PROJECT = gql`
  mutation ProjectCreate($projectInput: ProjectInput!) {
    projectCreate(projectInput: $projectInput) {
      id
    }
  }
`;

const defaultValues = {
  identifier: '',
  name: '',
  description: '',
};

const fieldsConfig = {
  identifier: {
    name: 'identifier',
    label: 'Identifier',
    validation: {
      required: true,
      pattern: /\d{3}/,
    },
    validationMessages: {
      pattern: 'Must be 3 digits',
    },
  },
  name: {
    name: 'name',
    label: 'Name',
    validation: {
      required: true,
    },
  },
  description: {
    name: 'description',
    label: 'Description',
  },
};

interface FormData {
  identifier: string;
  name: string;
  description: string;
}

export default () => {
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    setValue,
  } = useForm<FormData>({ defaultValues });

  const { loading, error, data } = useQuery(GET_NEXT_AVAILABLE_PROJECT_IDENTIFIER, {
    fetchPolicy: 'network-only',
  });

  const [createProject, {
    loading: createProjectLoading,
  }] = useMutation(CREATE_PROJECT, {
    onCompleted: () => {
      toaster.show({ intent: Intent.SUCCESS, message: 'Project created successfully' });
      navigate('/projects');
    },
    onError: ({ message }) => toaster.show({
      intent: Intent.DANGER,
      message: `Error creating project: ${message}`,
    }),
  });

  // Initialize form state
  useEffect(() => {
    if (!data) return;

    setValue('identifier', data.nextAvailableIdentifier);
  }, [data, setValue]);

  const onSubmit = async (formData: any) => {
    const variables = {
      projectInput: {
        identifier: formData.identifier,
        name: formData.name,
        description: (formData.description === '') ? null : formData.description, // Don't set to empty string if not provided
      },
    };

    await createProject({ variables });
  };

  if (loading) return <Spinner />;
  if (error) throw error;

  return (
    <div className={styles.container}>
      <h1 className="bp4-heading">Add Project</h1>
      <form className={styles.form}>
        <ProjectFormFields
          control={control}
          fieldsConfig={fieldsConfig}
        />

        <FormButtons
          submitHandler={handleSubmit(onSubmit)}
          disabled={createProjectLoading}
        />
      </form>
    </div>
  );
};
