import React from 'react';
import { Spinner, SpinnerProps } from '@blueprintjs/core';

import styles from './index.module.css';

export default (props: SpinnerProps) => {
  return (
    <div className={styles.container}>
      <Spinner className={styles.spinner} {...props} />
    </div>
  );
};
