import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { gql, useQuery } from '@apollo/client';
import { Colors } from '@blueprintjs/core';
import { useLocation, useSearchParams } from 'react-router-dom';
import {
  useForm,
  useFieldArray,
} from 'react-hook-form';
import classNames from 'classnames';
import _ from 'lodash';

import CategoryFilter from 'components/Category/CategoryFilter';
import PartList from 'components/Part/PartList';
import Spinner from 'components/Spinner';
import useProjectByParam from 'hooks/use-project-by-param';
import { selectDarkMode } from 'reducers/ui';

import { determinePlurality } from 'helpers/general';
import styles from './index.module.css';

const GET_PART_LIST_DATA = gql`
  query PartListTableAndFilters($projectId: Int, $categoryId: Int, $categoryPath: String!, $partIds: [Int]) {
    category: categoryByPath(path: $categoryPath) {
      id
      name
      description
    }
    table: partListTable(projectId: $projectId, categoryId: $categoryId, categoryPath: $categoryPath, partIds: $partIds) {
      columns
      rows {
        link
        values
      }
    }
    filters: partListFilters(categoryId: $categoryId, categoryPath: $categoryPath, partIds: $partIds) {
      config {
        label
        required
      }
      options {
        label
        value
      }
    }
  }
`;

const filter = (rows: any[], filters: any) => {
  return _.filter(rows, (row: any) => {
    // Don't include global part number or short description columns
    // `row.values` shape: `[globalPartNumber, shortDescription, ...values]`
    const rowValues = row.values.slice(2, row.values.length);

    return _.every(rowValues, (rowValue, index) => {
      if (_.isEmpty(filters[index])) return true;
      return _.includes(filters[index], rowValue);
    });
  });
};

const clearFilters = (numFilters: number, setValue: any) => _.times(numFilters, i => setValue(`filters.${i}`, []));

const defaultValues = {
  filters: [],
};

interface FormData {
  filters: { label?: string, value: string }[][];
}

export default () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const projectIdParam = searchParams.get('projectId');
  const projectId = (projectIdParam) && Number(projectIdParam);
  const darkMode = useSelector(selectDarkMode);

  const [filteredParts, setFilteredParts] = useState<any>([]);

  const {
    control,
    watch,
    setValue,
    getValues,
  } = useForm<FormData>({ defaultValues });
  const {
    append,
  } = useFieldArray({
    control,
    name: 'filters',
  });

  const variables: any = {
    categoryPath: location.pathname,
  };

  if (projectId) variables.projectId = projectId;

  const { loading, error, data } = useQuery(GET_PART_LIST_DATA, {
    variables,
    fetchPolicy: 'network-only', // Ensures that category property override values are fetched after update
  });

  const {
    loading: projectLoading,
    error: projectError,
    data: projectData,
  } = useProjectByParam();

  // Initialize part list, properties, and filters
  useEffect(() => {
    if (!data) return;

    const filters = _.times(data.filters.length, i => []);
    append(filters);
  }, [append, data]);

  // Change filtered part list when filters change
  useEffect(() => {
    const subscription = watch((value: any) => {
      const updatedFilteredParts = filter(data.table.rows, value.filters);
      setFilteredParts(updatedFilteredParts);
    });

    return () => subscription.unsubscribe();
  }, [data, getValues, watch]);

  if (loading || projectLoading) return <Spinner />;
  if (error || projectError) throw error ?? projectError;

  return (
    <>
      {data.filters.length > 0 && (
        <CategoryFilter
          filters={data.filters}
          control={control}
          clearFilters={() => clearFilters(data.filters.length, setValue)}
        />
      )}

      <div className={styles.container}>
        <h1
          className={classNames('bp4-heading', styles.heading)}
          style={{
            backgroundColor: darkMode ? Colors.DARK_GRAY2 : Colors.LIGHT_GRAY2,
          }}
        >
          {data.category.name} {projectData ? `for ${projectData.project.name}` : ''}
        </h1>

        {data.category.description && (<p>{data.category.description}</p>)}

        <PartList
          categoryId={data.category.id}
          columns={[
            `${filteredParts.length} ${determinePlurality(filteredParts.length, 'part')}`, // Override part count
            ...data.table.columns.slice(1, data.table.columns.length),
          ]}
          rows={filteredParts}
        />
      </div>
    </>
  );
};
