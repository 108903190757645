import React from 'react';
import {
  Button,
  Intent,
} from '@blueprintjs/core';
import classNames from 'classnames';

import Select from 'components/FormFields/Select';
import { parseFractionStr } from 'helpers/general';

import styles from './index.module.css';

export default (props: any) => {
  const renderFilter = (filter: any, index: number) => {
    let opts = filter.options;

    // Sort fraction strings by their numeric value instead of lexographically
    if (filter.config.label === 'Length') {
      opts = filter.options.sort((a: any, b: any) => parseFractionStr(a.value) - parseFractionStr(b.value));
    }

    return (
      <div key={index} className={styles.propertyContainer}>
        <Select
          control={props.control}
          config={{
            name: `filters.${index}`,
            label: filter.config.label,
            multiple: true,
          }}
          options={opts}
          selectClassName={styles.valueSelect}
        />
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.listContainer}>
        <Button
          className={classNames('bp4-button', styles.clearFiltersButton)}
          fill
          intent={Intent.DANGER}
          onClick={props.clearFilters}
        >
          Clear All
        </Button>
        <div>
          {props.filters.map(renderFilter)}
        </div>
      </div>
    </div>
  );
};
