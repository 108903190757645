import React, { useState } from 'react';
import {
  gql,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import { Alert, Button, ButtonGroup, Intent } from '@blueprintjs/core';
import classNames from 'classnames';

import Spinner from 'components/Spinner';
import useCheckPermission from 'hooks/use-check-permission';

import toaster from 'helpers/toaster';
import styles from './index.module.css';

const GET_PROJECT = gql`
  query ProjectById($projectId: Int!) {
    project: projectById(projectId: $projectId) {
      id
      identifier
      name
      description
    }
  }
`;

const DELETE_PROJECT = gql`
  mutation DeleteProject($projectId: Int!) {
    projectRemove(projectId: $projectId)
  }
`;

export default () => {
  const navigate = useNavigate();
  const params = useParams();
  const projectId = Number(params.projectId);
  const [deleteAlertIsOpen, setDeleteAlertIsOpen] = useState(false);

  const [canEditProjects] = useCheckPermission('update_projects');
  const [canDeleteProjects] = useCheckPermission('delete_projects');

  const { loading, error, data } = useQuery(GET_PROJECT, {
    variables: { projectId },
  });

  const [deleteProject] = useMutation(DELETE_PROJECT, {
    ignoreResults: true,
    onCompleted: () => {
      toaster.show({
        intent: Intent.SUCCESS,
        message: 'Deleted successfully',
      });
      navigate('/projects');
    },
    onError: ({ message }) => toaster.show({
      intent: Intent.DANGER,
      message,
    }),
  });

  const onConfirmDeleteClick = async () => {
    await deleteProject({ variables: { projectId: data.project.id } });
  };

  const renderActionButtons = () => {
    return (
      <ButtonGroup>
        {canEditProjects && (
          <Button
            icon="edit"
            onClick={() => navigate(`/projects/edit/${projectId}`)}
          >
            Edit
          </Button>
        )}
        {canDeleteProjects && (
          <>
            <Alert
              isOpen={deleteAlertIsOpen}
              canEscapeKeyCancel
              canOutsideClickCancel
              cancelButtonText="Cancel"
              confirmButtonText="Delete"
              intent={Intent.DANGER}
              icon="trash"
              onConfirm={onConfirmDeleteClick}
              onCancel={() => setDeleteAlertIsOpen(false)}
            >
              Are you sure you want to delete this project?
              <br />
              <br />
              <strong>All channels that rely on this project will also be deleted.</strong>
              <br />
              <br />
              Once deleted, it cannot be restored.
            </Alert>
            <Button
              icon="trash"
              onClick={() => setDeleteAlertIsOpen(true)}
            >
              Delete
            </Button>
          </>
        )}
      </ButtonGroup>
    );
  };

  if (loading) return <Spinner />;
  if (error) throw error;

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h1 className={classNames(styles.heading, 'bp4-heading')}>{data.project.name}</h1>
        {renderActionButtons()}
      </header>
      <p>Identifier: {data.project.identifier}</p>
      <p>Description: {data.project.description}</p>
    </div>
  );
};
