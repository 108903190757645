import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import classnames from 'classnames';
import { Classes, Colors } from '@blueprintjs/core';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { env, sentryDSN, commitHash, packageJson } from 'config';
import SectionNav from 'components/SectionNav';
import ErrorBoundary from 'components/ErrorBoundary';
import GlobalSearch from 'components/GlobalSearch';
import ApexToolbar from 'components/ApexToolbar';
import { selectDarkMode } from 'reducers/ui';

import styles from './index.module.css';

if (sentryDSN && !['local', 'test'].includes(env)) {
  Sentry.init({
    dsn: sentryDSN,
    integrations: [new Integrations.BrowserTracing()],
    environment: env,
    release: `${packageJson.version}-${commitHash}`,
  });

  Sentry.configureScope(scope => {
    scope.setTag('pkg_version', packageJson.version);
    scope.setUser({ email: JSON.parse(window?.localStorage?.user_details || '{}')?.email });
  });
}

export default () => {
  const darkMode = useSelector(selectDarkMode);

  return (
    <div className={styles.root}>
      <ApexToolbar />

      <div
        style={{
          backgroundColor: darkMode ? Colors.DARK_GRAY2 : Colors.LIGHT_GRAY2,
        }}
        className={classnames({
          [Classes.DARK]: darkMode,
          [styles.content]: true,
        })}
      >
        <div className={styles.globalActionsContainer}>
          <ErrorBoundary>
            <SectionNav />
            <GlobalSearch />
          </ErrorBoundary>
        </div>
        <main className={styles.fill}>
          <ErrorBoundary>
            <Outlet />
          </ErrorBoundary>
        </main>
      </div>
    </div>
  );
};
