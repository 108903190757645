import React, { useEffect, useState } from 'react';
import {
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import {
  gql,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  useForm,
  useFieldArray,
  useWatch,
  FormProvider,
} from 'react-hook-form';
import {
  Callout,
  Intent,
} from '@blueprintjs/core';
import classNames from 'classnames';
import { marked } from 'marked';
import _ from 'lodash';

import Spinner from 'components/Spinner';
import toaster from 'helpers/toaster';
import PartFormFields from 'components/Part/PartFormFields';
import PartPropertyFormFields from 'components/Part/PartPropertyFormFields';
import PartMatcher from 'components/Part/PartMatcher';
import FormButtons from 'components/FormButtons';

import styles from './index.module.css';

const GET_LEAF_CATEGORIES = gql`
  query CategoryLeaves {
    categories: categoryLeaves {
      id
      identifier
      name
    }
  }
`;

const GET_CATEGORY = gql`
  query GetCategory($categoryId: Int!) {
    category: categoryById(categoryId: $categoryId) {
      id
      identifier
      name
      hierarchy
      instructions
      categoryProperties {
        id
        propertyId
        description
        min
        max
        values
        sizeGraded
        sizeGrades {
          id
          identifier
          value
        }
        required
        property {
          id
          name
          description
          type
          min
          max
          unit
          values
        }
      }
    }

  }
`;

const GET_PROJECTS = gql`
  query GetProjects {
    projects {
      id
      identifier
      name
      description
    }
  }
`;

const GET_ROOT_PART = gql`
  query PartById($partId: Int!) {
    rootPart: partById(partId: $partId) {
      id
      shortDescription
      longDescription
      category {
        id
        identifier
      }
      project {
        id
        identifier
      }
      partNumber
      globalPartNumber
      properties {
        partPropertyId
        value
        categoryProperty {
          id
          description
          min
          max
          values
          sizeGraded
          sizeGrades {
            id
            identifier
            value
          }
          property {
            id
            name
            description
            type
            min
            max
            unit
            values
          }
        }
      }
    }
  }
`;

const CREATE_PART = gql`
  mutation PartCreate($partInput: PartInput!, $propertyInputs: [PartPropertyInput]) {
    part: partCreate(partInput: $partInput, propertyInputs: $propertyInputs) {
      id
      globalPartNumber
      category {
        path
      }
    }
  }
`;

const defaultValues = {
  partNumber: '',
  shortDescription: '',
  longDescription: '',
  categoryId: '',
  projectId: '',
  legacyPartNumbers: [],
  properties: [],
};

const fieldsConfig = {
  partNumber: {
    name: 'partNumber',
    validation: {
      required: true,
      pattern: /\d{3}/,
    },
    validationMessages: {
      pattern: 'Must be 3 digits',
    },
  },
  shortDescription: {
    name: 'shortDescription',
    label: 'Short description',
    validation: {
      required: true,
      maxLength: 50,
    },
    validationMessages: {
      maxLength: 'Must be less than 50 characters',
    },
  },
  longDescription: {
    name: 'longDescription',
    label: 'Long description',
    validation: {
      maxLength: 100,
    },
    validationMessages: {
      maxLength: 'Must be less than 100 characters',
    },
  },
  categoryId: {
    name: 'categoryId',
    label: 'Category',
  },
  projectId: {
    name: 'projectId',
    label: 'Project',
  },
  legacyPartNumbers: {
    name: 'legacyPartNumbers',
    label: 'Legacy part numbers',
  },
};

interface PropertyValueFormData {
  label: string;
  value: string;
}

// TODO: Validate this because I needed to add a bunch of optionals to get it working again.
interface PropertyFormData {
  partId?: number;
  propertyId?: number;
  categoryPropertyId: number;
  name: string;
  label: string;
  value: string;
  min?: number;
  max?: number;
  type?: string;
  values?: any[];
  sizeGraded?: boolean;
  sizeGrades?: any[];
  required?: boolean;
  usingOverrides?: boolean;
  partPropertyId?: number;
  defaultOptions?: PropertyValueFormData[],
  overrideOptions?: PropertyValueFormData[] | null,
}

interface FormData {
  partNumber: string;
  shortDescription: string;
  longDescription: string;
  categoryId: string | number;
  projectId: string | number;
  legacyPartNumbers: string[];
  properties: PropertyFormData[];
}

const formatCategories = (categories: any[] = []): PropertyValueFormData[] => {
  return categories
    .map(category => ({
      label: `${category.name} (${category.identifier})`,
      value: category.id,
    }));
};

const formatProjects = (projects: any[] = []): PropertyValueFormData[] => {
  return projects.map((project: any) => ({
    label: `${project.name} (${project.identifier})`,
    value: project.id,
  }));
};

const buildPropertyFormLabel = (name: string, unit: string | null) => {
  if (unit === null) return name;
  return `${name} (${unit})`;
};

// If basing this part on another one, initialize with the root part's actual value.
// Otherwise, use the first category property override value (if overrides exist).
// If neither of the above conditions are met, then use the first default property value.
const getPropertyValue = (
  min: number,
  max: number,
  values: { value: string }[],
  partPropertyId: number,
  rootPartData: any,
) => {
  const property = _.find(rootPartData?.rootPart?.properties, {
    categoryProperty: { property: { id: partPropertyId } },
  });

  return _.get(property, 'value', values[0]?.value ?? min ?? max);
};

const generatePropertyFormData = (categoryProperties: [], rootPartData: any) => {
  return categoryProperties.map((categoryProperty: any) => {
    const min = categoryProperty.min ?? categoryProperty.property.min;
    const max = categoryProperty.max ?? categoryProperty.property.max;
    const defaultOptions = categoryProperty.property.values.map((value: string) => ({ value }));
    const overrideOptions = categoryProperty.values?.map((value: string) => ({ value })) ?? [];
    let values = defaultOptions;
    let usingOverrides = false;
    if (overrideOptions.length > 0) {
      values = overrideOptions;
      usingOverrides = true;
    }
    return {
      propertyId: categoryProperty.property.id,
      categoryPropertyId: categoryProperty.id,
      name: categoryProperty.property.name,
      label: buildPropertyFormLabel(categoryProperty.property.name, categoryProperty.property.unit),
      value: getPropertyValue(min, max, values, categoryProperty.property.id, rootPartData),
      min,
      max,
      type: categoryProperty.property.type,
      values,
      sizeGraded: categoryProperty.sizeGraded,
      sizeGrades: categoryProperty.sizeGrades,
      required: categoryProperty.required,
      usingOverrides,
    };
  });
};

const renderInstructions = (instructions: string) => {
  if (!instructions) return null;

  return (
    <Callout
      title="Instructions"
      icon="info-sign"
      intent="primary"
      className={styles.instructions}
    >
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: marked(instructions) }}
      />
    </Callout>
  );
};

export default () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const rootPartId = Number(searchParams.get('rootPartId')) || null;
  const [initialized, setInitialized] = useState(false);

  // Sets up the form and any needed watches
  const form = useForm<FormData>({
    defaultValues: {
      ...defaultValues,
      categoryId: Number(searchParams.get('categoryId')) || '',
      projectId: searchParams.get('projectId') || '',
    },
  });
  const { handleSubmit, control, setValue } = form;
  const { fields, replace } = useFieldArray({
    control,
    name: 'properties',
  });
  const categoryId = useWatch({ control, name: 'categoryId' });
  const projectId = useWatch({ control, name: 'projectId' });
  const partNumber = useWatch({ control, name: 'partNumber' });
  const shortDescription = useWatch({ control, name: 'shortDescription' });
  const longDescription = useWatch({ control, name: 'longDescription' });
  const legacyPartNumbers = useWatch({ control, name: 'legacyPartNumbers' });
  const properties = useWatch({ control, name: 'properties' });

  // Gets all categories and sets the `categoryId` value if one isn't already
  // set (from `searchParams`)
  const [categories, setCategories] = useState<any[]>([]);
  const { data: leafCategoriesData } = useQuery(GET_LEAF_CATEGORIES);

  useEffect(() => {
    const leafCategories = [...leafCategoriesData?.categories ?? []];
    leafCategories.sort((a, b) => {
      return (/^[0-9]/.test(a.identifier) as any) - (/^[0-9]/.test(b.identifier) as any)
        || a.identifier.localeCompare(b.identifier, undefined, { numeric: true });
    });
    setCategories(leafCategories);
    if (!categoryId && leafCategories.length > 0) {
      setValue('categoryId', leafCategories[0].id);
    }
  }, [categoryId, leafCategoriesData, setCategories, setValue]);

  // When the selected category changes, fetches the full category data
  const [getCategory, {
    loading: categoryLoading,
    error: categoryError,
    data: categoryData,
  }] = useLazyQuery(GET_CATEGORY, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (categoryId) {
      getCategory({
        variables: { categoryId: Number(categoryId) },
      });
    }
  }, [categoryId, getCategory]);

  // Gets all projects from the beginning
  const {
    loading: projectsLoading,
    error: projectsError,
    data: projectsData,
  } = useQuery(GET_PROJECTS);

  // Gets root part data if relevant
  const {
    loading: rootPartLoading,
    error: rootPartError,
    data: rootPartData,
  } = useQuery(GET_ROOT_PART, {
    variables: { partId: rootPartId },
    skip: !rootPartId,
  });

  const [createPart, {
    loading: createPartLoading,
  }] = useMutation(CREATE_PART, {
    onCompleted: () => {
      toaster.show({
        intent: Intent.SUCCESS,
        message: 'Part created successfully',
      });
    },
    onError: ({ message }) => {
      toaster.show({
        intent: Intent.DANGER,
        message: `Error creating part: ${message}`,
      });
    },
  });

  // Initialize form fields
  useEffect(() => {
    if (!categoryData || !projectsData || (rootPartId && !rootPartData)) return;

    // If the category identifier starts with Y or Z, set the project to "Purchased" project
    if (/^[YZ]/.test(categoryData?.category?.identifier)) {
      setValue('projectId', 3);
    }

    const propertyData = generatePropertyFormData(categoryData.category.categoryProperties, rootPartData)
      .sort((a: any, b: any) => { // Alphabetically sort by label
        if (a.label > b.label) return 1;
        if (a.label < b.label) return -1;
        return 0;
      });

    // Prevents dynamic form from appending the same property form field multiple times.
    // Does not lose the modified values.
    replace(propertyData);

    // Without the `initialized` check, this runs any time category or project dropdown changes
    if (!initialized && rootPartData) {
      setValue('projectId', rootPartData.rootPart.project.id);
      setValue('shortDescription', rootPartData.rootPart.shortDescription);
      setValue('longDescription', rootPartData.rootPart.longDescription);
      setInitialized(true);
    }
  }, [categoryData, categoryId, initialized, projectId, projectsData, replace, rootPartData, rootPartId, setValue]);

  const onSubmit = async (formData: any) => {
    const partCreate = await createPart({
      variables: {
        partInput: {
          rootPartId,
          categoryId: Number(formData.categoryId),
          projectId: Number(formData.projectId),
          partNumber: formData.partNumber,
          legacyPartNumbers: formData.legacyPartNumbers,
          shortDescription: formData.shortDescription,
          longDescription: formData.longDescription,
        },
        propertyInputs: formData.properties.map((partProperty: any) => ({
          categoryPropertyId: partProperty.categoryPropertyId,
          value: partProperty.value,
        })),
      },
    });

    const hasProjectIdSearchParam = searchParams.get('projectId') !== null;
    navigate(`/categories/${partCreate.data.part.category.path}${(hasProjectIdSearchParam ? `?projectId=${searchParams.get('projectId')}` : '')}`);
  };

  if (categoryLoading || projectsLoading || rootPartLoading) {
    return (
      <div className={styles.container}>
        <Spinner />
      </div>
    );
  }

  // Allows GraphQL errors to be handled by the ErrorBoundary; implementing
  // ManualError here would have the same UX
  if (categoryError || projectsError || rootPartError) {
    throw categoryError ?? projectsError ?? rootPartError;
  }

  return (
    <div className={styles.container}>
      <div>
        <h1 className={classNames('bp4-heading', styles.title)}>Add New {categoryData?.category.name ?? 'Part'}</h1>

        <FormProvider {...form}>
          <form className={styles.form}>
            {renderInstructions(categoryData?.category.instructions)}

            <PartFormFields
              fieldsConfig={fieldsConfig}
              categories={formatCategories(categories)}
              projects={formatProjects(projectsData?.projects)}
            />

            {fields.length > 0 && (
              <div className={styles.propertiesContainer}>
                <h2 className="bp4-heading">Properties</h2>
                <PartPropertyFormFields
                  categoryId={Number(categoryId)}
                  partNumber={partNumber}
                  fields={fields}
                />
              </div>
            )}

            <FormButtons
              submitHandler={handleSubmit(onSubmit)}
              disabled={createPartLoading}
            />
          </form>
        </FormProvider>
      </div>
      <div>
        <PartMatcher
          shortDescription={shortDescription}
          longDescription={longDescription}
          legacyPartNumbers={legacyPartNumbers}
          properties={properties}
        />
      </div>
    </div>
  );
};
