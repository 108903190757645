import { useEffect, useState } from 'react';

export default function useCheckPermission(permission: string): [boolean | null, boolean] {
  const [result, setResult] = useState<boolean | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    async function checkUserPermission(): Promise<void> {
      try {
        setLoading(true);
        const response = await fetch(`/auth/check-user-permission/${permission}`);
        const json = await response.json();
        setLoading(false);
        setResult(json.data.authorized);
      } catch (error: any) {
        setLoading(false);
        setResult(false);
      }
    }

    if (result === null) checkUserPermission();
  }, [permission, result]);

  return [result, loading];
}
