import { useCallback, useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import _ from 'lodash';

import { SearchInput } from 'reducers/search';

const SEARCH_DEBOUNCE_IN_MS = 1000;
const FUZZY_SEARCH_PARTS = gql`
  query FuzzySearchParts($searchInput: SearchInput!) {
    search(searchInput: $searchInput) {
      id
      globalPartNumber
      categoryHierarchy
      shortDescription
      legacyPartNumbers
      category {
        path
      }
      properties {
        value
        categoryProperty {
          visible
          property {
            name
          }
        }
      }
    }
  }
`;
export const STATIC_PARAM_MAP: Record<string, string> = {
  partNumber: 'Part Number',
  projectNameOrIdentifier: 'Project',
  categoryNameOrIdentifier: 'Category',
  description: 'Description',
  legacyPartNumbers: 'Legacy Part Number',
};
export const STATIC_PARAM_MAP_INVERTED = _.invert(STATIC_PARAM_MAP);

const hasSearchInput = (searchInput: SearchInput) => {
  return searchInput.partNumber
    || searchInput.projectNameOrIdentifier
    || searchInput.categoryNameOrIdentifier
    || searchInput.description
    || searchInput.legacyPartNumbers.length !== 0
    || searchInput.propertyValues.length !== 0;
};

type SearchValue = number | string | string[] | string[][];

export default () => {
  const initialState = {
    partNumber: '',
    projectNameOrIdentifier: '',
    categoryNameOrIdentifier: '',
    description: '',
    legacyPartNumbers: [],
    propertyValues: [],
    limit: 10,
  };
  const [searchInput, setSearchInput] = useState(initialState);
  const [searchResults, setSearchResults] = useState([]);
  const [fuzzySearchParts, { loading, error }] = useLazyQuery(FUZZY_SEARCH_PARTS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setSearchResults(data?.search ?? []);
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const search = useCallback(_.debounce(input => {
    fuzzySearchParts({
      variables: {
        searchInput: {
          partNumber: input.partNumber || null,
          projectNameOrIdentifier: input.projectNameOrIdentifier || null,
          categoryNameOrIdentifier: input.categoryNameOrIdentifier || null,
          description: input.description || null,
          legacyPartNumbers: input.legacyPartNumbers || [],
          propertyValues: input.propertyValues || [],
          limit: input.limit,
        },
      },
    });
  }, SEARCH_DEBOUNCE_IN_MS), []);

  const handleSearchInputChange = (field: string, value?: SearchValue) => {
    // Empties results on input change to avoid momentarily displaying
    // stale results
    setSearchResults([]);
    setSearchInput(input => {
      const updatedSearchInput = {
        ...input,
        [field]: value,
      };
      if (hasSearchInput(updatedSearchInput)) search(updatedSearchInput);
      return updatedSearchInput;
    });
  };

  const resetSearchInput = () => {
    setSearchResults([]);
    setSearchInput(initialState);
  };

  return {
    searchInput,
    handleSearchInputChange,
    resetSearchInput,
    hasSearchInput,
    searchLoading: loading,
    searchError: error,
    searchData: searchResults,
  };
};
