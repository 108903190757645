import React, { useEffect } from 'react';
import {
  Link,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import {
  gql,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  FormProvider,
  useForm,
} from 'react-hook-form';
import {
  Intent,
} from '@blueprintjs/core';

import CategoryFormFields from 'components/Category/CategoryFormFields';
import FormButtons from 'components/FormButtons';
import Spinner from 'components/Spinner';
import toaster from 'helpers/toaster';
import { determinePlurality } from 'helpers/general';

import styles from './index.module.css';

const GET_PARENT_CATEGORY = gql`
  query ParentCategory($categoryId: Int!) {
    parentCategory: categoryById(categoryId: $categoryId) {
      id
      name
      partCount
      categoryPropertyCount
      path
      nextAvailableIdentifier
    }
  }
`;

const CREATE_CATEGORY = gql`
  mutation CreateCategory($categoryInput: CategoryInput!) {
    category: categoryCreate(categoryInput: $categoryInput) {
      id
      identifier
      name
      path
    }
  }
`;

const defaultValues = {
  name: '',
  identifier: '',
  description: '',
};

const fieldsConfig = {
  name: {
    name: 'name',
    label: 'Name',
    validation: {
      required: true,
    },
  },
  identifier: {
    name: 'identifier',
    label: 'Identifier',
    pattern: /^\d{5}$|^[a-su-zA-SU-Z]{1}\d{3}$/, // Excludes "T" from the subcategory prefix
    validationMessages: {
      pattern: 'Must follow the pattern "A000" (or "00000" for the TMC category)',
    },
  },
  description: {
    name: 'description',
    label: 'Description',
  },
  instructions: {
    name: 'instructions',
    label: 'Instructions',
  },
};

interface FormData {
  name: string;
  identifier: string;
  description: string;
  instructions: string;
}

export default () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const rawParentCategoryId = searchParams.get('parentCategoryId');
  const parentCategoryId = rawParentCategoryId ? Number(rawParentCategoryId) : null;
  const form = useForm<FormData>({ defaultValues });

  const {
    loading: parentCategoryLoading,
    data: parentCategoryData,
  } = useQuery(GET_PARENT_CATEGORY, {
    variables: { categoryId: parentCategoryId },
    skip: parentCategoryId === null,
    fetchPolicy: 'cache-and-network',
  });

  const [createCategory, {
    loading: createCategoryLoading,
  }] = useMutation(CREATE_CATEGORY, {
    onCompleted: ({ category }) => {
      toaster.show({ intent: Intent.SUCCESS, message: `Created ${category.name} with prefix ${category.identifier}` });
      navigate(`/categories/${category.path}`);
    },
    onError: ({ message }) => toaster.show({
      intent: Intent.DANGER,
      message: `Error creating category: ${message}`,
    }),
  });

  // Initialize form state
  useEffect(() => {
    if (!parentCategoryData) return;

    form.setValue('identifier', parentCategoryData.parentCategory.nextAvailableIdentifier);
  }, [form, parentCategoryData]);


  const renderTitle = () => {
    if (parentCategoryId === null) return <h1 className="bp4-heading">Add New Root Category</h1>;
    return <h1 className="bp4-heading">Add New {parentCategoryData.parentCategory.name} Subcategory</h1>;
  };

  const onSubmit = async (formData: any) => {
    await createCategory({
      variables: {
        categoryInput: {
          parentCategoryId,
          identifier: formData.identifier,
          name: formData.name,
          description: formData.description || null,
          instructions: formData.instructions || null,
        },
      },
    });
  };

  const renderPartMoveNotice = () => {
    const { partCount, name, path } = parentCategoryData.parentCategory;
    const noun = determinePlurality(partCount, 'part');

    if (partCount === 0) return null;

    return (
      <Link to={`/categories/${path}`} target="_blank">
        <p>{partCount} {noun} will move from &quot;{name}&quot; to this category.</p>
      </Link>
    );
  };

  const renderCategoryPropertyMoveNotice = () => {
    const { categoryPropertyCount, name, path } = parentCategoryData.parentCategory;
    const noun = determinePlurality(categoryPropertyCount, 'property', 'properties');

    if (categoryPropertyCount === 0) return null;

    return (
      <Link to={`/categories/edit/${path}`} target="_blank">
        <p>{categoryPropertyCount} {noun} will move from &quot;{name}&quot; to this category.</p>
      </Link>
    );
  };

  if (parentCategoryLoading) return <Spinner />;

  return (
    <div>
      {renderTitle()}
      <FormProvider {...form}>
        <form className={styles.form}>
          <CategoryFormFields
            fieldsConfig={fieldsConfig}
          />

          {parentCategoryData && renderPartMoveNotice()}
          {parentCategoryData && renderCategoryPropertyMoveNotice()}

          <FormButtons
            submitHandler={form.handleSubmit(onSubmit)}
            disabled={createCategoryLoading}
          />
        </form>
      </FormProvider>
    </div>
  );
};
