import React, { useEffect } from 'react';
import _ from 'lodash';
import { Icon, NonIdealState, Spinner } from '@blueprintjs/core';

import useFuzzySearch from 'hooks/use-fuzzy-search';
import PartList from 'components/Part/PartList';
import styles from './index.module.css';

const renderSearchResults = (parts: any[]) => {
  if (parts.length === 0) return <NonIdealState icon="tick" title="No matching parts found" />;

  return (
    <PartList
      partIds={_.map(parts, 'id')}
      alwaysOpenInNewTab
    />
  );
};

interface Props {
  shortDescription: string;
  longDescription: string;
  legacyPartNumbers: string[];
  properties: any[];
}

export default (props: Props) => {
  const {
    handleSearchInputChange,
    searchLoading,
    searchError,
    searchData,
  } = useFuzzySearch();

  useEffect(() => {
    handleSearchInputChange('description', [props.shortDescription, props.longDescription].join(' '));
    // Adding `handleSearchInputChange` to the list of deps causes an infinite rerender loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.shortDescription, props.longDescription]);

  useEffect(() => {
    handleSearchInputChange('legacyPartNumbers', props.legacyPartNumbers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.legacyPartNumbers]);

  useEffect(() => {
    handleSearchInputChange('propertyValues', props.properties.map(property => ([property.name, property.value])));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.properties]);

  if (searchLoading) return <Spinner className={styles.spinner} />;
  if (searchError) return <p>Error: {searchError.message}</p>;

  return (
    <div className={styles.container}>
      <p>Existing Matches <Icon icon="help" htmlTitle="These existing parts closely or exactly match the information entered" /></p>
      <div className={styles.resultsContainer}>
        {renderSearchResults(searchData)}
      </div>
    </div>
  );
};
