import React, { useEffect, useState } from 'react';
import {
  gql,
  useQuery,
} from '@apollo/client';
import { Spinner, SpinnerSize } from '@blueprintjs/core';
import _ from 'lodash';
import { useFieldArray, useForm } from 'react-hook-form';

import Table from 'components/Table';
import AuditFilter from 'components/Audit/AuditFilter';

import styles from './index.module.css';

const GET_AUDIT_LOG = gql`
  query Audit {
    audit {
      id
      operation
      type
      message
      recordId
      email
      timestamp
    }
  }
`;

const formatRow = (record: any) => {
  return [
    _.startCase(record.operation),
    _.startCase(record.type),
    record.recordId,
    record.message,
    record.email,
    `${new Date(record.timestamp).toLocaleDateString()} ${new Date(record.timestamp).toLocaleTimeString('en-US', { timeZoneName: 'short' })}`,
  ];
};

const rowRenderer = (row: any, rowIndex: number) => {
  return (
    <tr key={`row-${rowIndex}`}>
      {row.map((value: any, colIndex: number) => <td key={`row-${rowIndex}-cell-${colIndex}`}>{value}</td>)}
    </tr>
  );
};

const filterNames = [
  'operation',
  'type',
  'email',
];

const filterAuditRecords = (records: any[], filters: any, properties: any[]) => {
  return _.filter(records, (record: any) => {
    const activeFilters = _.reduce(filters, (acc: any, values: string[], index: number) => {
      const key = properties[index].id;
      // If the given property doesn't have any values selected to filter, then skip.
      if (_.isEmpty(values)) return acc;

      // Update this filter object to reflect the updated values for the given property.
      acc[`${key}`] = values;
      return acc;
    }, {});

    if (_.isEmpty(activeFilters)) return true; // No active filters so show all records.

    // Find the records that match all of the selected filters.
    return _.every(activeFilters, (activeFilter, key) => {
      const columnIndex = parseInt(key, 10);
      const propertyName = filterNames[columnIndex];
      return activeFilter.includes(record[propertyName]);
    });
  });
};

const clearFilters = (numFilters: number, setValue: any) => _.times(numFilters, i => setValue(`filters.${i}`, []));

const defaultValues = {
  filters: [],
};

interface FormData {
  filters: string[][];
}

const columns = [
  'Operation',
  'Type',
  'Record ID',
  'Message',
  'User',
  'Timestamp',
];

export default () => {
  const { loading, error, data } = useQuery(GET_AUDIT_LOG, { fetchPolicy: 'network-only' });
  const [properties, setProperties] = useState<any>([]);
  const [filteredAuditRecords, setFilteredAuditRecords] = useState<any>([]);

  const {
    control,
    watch,
    setValue,
    getValues,
  } = useForm<FormData>({ defaultValues });
  const {
    append,
  } = useFieldArray({
    control,
    name: 'filters',
  });

  // Initialize audit records, properties, and filters
  useEffect(() => {
    if (!data) return;

    const sortedAuditRecords = data.audit.sort((a: any, b: any) => {
      const aTimestampInMilliseconds = new Date(a.timestamp).getTime();
      const bTimestampInMilliseconds = new Date(b.timestamp).getTime();

      if (aTimestampInMilliseconds > bTimestampInMilliseconds) return -1;
      if (aTimestampInMilliseconds < bTimestampInMilliseconds) return 1;
      return 0;
    });

    setFilteredAuditRecords(sortedAuditRecords);

    const formattedProperties = filterNames.map((name: string, index: number) => {
      return {
        id: index,
        name,
        defaultValues: _.chain(data.audit)
          .map(name)
          .uniq()
          .value(),
      };
    });

    setProperties(formattedProperties);

    const filters = _.times(filterNames.length, i => []);
    append(filters);
  }, [append, data]);

  // Change filtered audit records when filters change
  useEffect(() => {
    const subscription = watch((value: any) => {
      if (!data) return;

      const updatedAuditRecords = filterAuditRecords(data.audit, value.filters, properties);
      setFilteredAuditRecords(updatedAuditRecords);
    });

    return () => subscription.unsubscribe();
  }, [data, getValues, properties, watch]);

  if (loading) return <Spinner size={SpinnerSize.SMALL} />;
  if (error) throw error;

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h1 className="bp4-heading">Audit Log</h1>
      </header>

      <div className={styles.content}>
        <AuditFilter
          properties={properties}
          control={control}
          clearFilters={() => clearFilters(data.audit.length, setValue)}
        />

        <div className={styles.tableWrapper}>
          <Table
            columns={columns}
            rows={filteredAuditRecords.map(formatRow)}
            rowRenderer={rowRenderer}
            sortable
            sticky={{ top: 0 }}
          />
        </div>
      </div>
    </div>
  );
};
