import React from 'react';
import PlatformToolbar from '@apex/react-toolkit/components/PlatformToolbar';

import {
  commitHash,
  env,
  packageJson,
} from 'config';
import DarkModeToggle from './DarkModeToggle';

import styles from './index.module.css';

export const reload = () => window.location.reload();

export default function ApexToolbar() {
  return (
    <PlatformToolbar
      // icon={faScrewdriverWrench} // TODO: Add icon
      name="Part Numbering"
      href={(env === 'development') ? '/part-numbering' : '/'}
      isTesting={env === 'development'}
      settingsComponent={(
        <div className={styles.toolbarSettingsContainer}>
          <div className={styles.toolbarSettings}>
            <DarkModeToggle />
          </div>
          <p>v{packageJson.version} ({commitHash})</p>
        </div>
      )}
    />
  );
}
