import React, { useState } from 'react';
import {
  FormGroup,
  FormGroupProps,
  TagInput,
  TagInputProps,
} from '@blueprintjs/core';
import { Controller } from 'react-hook-form';
import _ from 'lodash';

import ValidationMessage from './ValidationMessage';

interface FieldConfig {
  name: string;
  label?: string;
  validation?: any;
  validationMessages?: any;
  disabled?: boolean;
}

interface Props {
  control: any;
  config: FieldConfig;
  formGroupProps?: FormGroupProps;
  tagInputProps?: Partial<TagInputProps>;
}

const showValidationMessage = (formState: any, name: string, validationMessages: any) => {
  const type = formState.errors[name]?.type;
  if (!type) return null;

  if (type === 'required') return <ValidationMessage message="Required" />;

  return <ValidationMessage message={validationMessages[type]} />;
};

const handleInputChange = (e: any, setInputValue: any, field: any) => {
  const str = e.target.value;
  const strTrimmed = str.replace(/\s/g, '');

  // Ignore changes when they are only spaces
  if (strTrimmed === '') return;

  setInputValue(strTrimmed);

  // Check if last character should end the input (`space` or `,`)
  const match = /[\s,]/.test(str[str.length - 1]);

  if (!match) return;

  const valueToAdd = _.replace(strTrimmed, /,/g, '');

  field.onChange([...field.value, valueToAdd]);
  setInputValue('');
};

export const TagField = (props: Props) => {
  const [inputValue, setInputValue] = useState('');

  return (
    <Controller
      name={props.config.name}
      defaultValue={[]}
      control={props.control}
      rules={props.config.validation}
      render={({ field, formState }) => {
        return (
          <FormGroup
            label={props.config.label}
            {...props.formGroupProps}
          >
            <TagInput
              onAdd={addedValues => {
                field.onChange([...field.value, ...addedValues]);
                setInputValue('');
              }}
              addOnBlur
              addOnPaste
              onRemove={removedValue => field.onChange((field.value as string[]).filter(v => v !== removedValue))}
              onInputChange={e => handleInputChange(e, setInputValue, field)}
              inputValue={inputValue}
              separator={/[\s,\n\r]/}
              values={field.value}
              {...props.tagInputProps}
            />
            {showValidationMessage(formState, props.config.name, props.config.validationMessages)}
          </FormGroup>
        );
      }}
    />
  );
};

TagField.defaultProps = {
  formGroupProps: {},
  tagInputProps: {},
};

export default TagField;
