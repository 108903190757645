import React from 'react';
import { Location, useLocation } from 'react-router-dom';
import { Breadcrumbs, BreadcrumbProps, Breadcrumb, Spinner, SpinnerSize } from '@blueprintjs/core';
import {
  gql,
  useQuery,
} from '@apollo/client';

import useProjectByParam from 'hooks/use-project-by-param';

import { basename } from 'config';
import styles from './index.module.css';

const GET_CATEGORY_BREADCRUMBS = gql`
  query CategoryByPath($path: String!) {
    category: categoryByPath(path: $path) {
      id
      breadcrumbs {
        id
        name
        path
      }
    }
  }
`;

const buildBreadcrumbEntries = (breadcrumbs: any[], location: Location, project: any): BreadcrumbProps[] => {
  const { pathname, search } = location;

  if (pathname === `${basename}/categories`) return [];

  let entries = [{
    href: `${basename}/categories`,
    text: project ? 'All Projects' : 'All',
  }];

  if (project) {
    entries.push({
      href: `${basename}/categories${search}`,
      text: project.name,
    });
  }

  entries = entries.concat(breadcrumbs.map((breadcrumb: any) => ({
    href: `${basename}/categories/${breadcrumb.path}${search}`,
    text: breadcrumb.name,
  })));

  return entries;
};

export default () => {
  const location = useLocation();
  const { data: projectData } = useProjectByParam();
  const { loading, error, data } = useQuery(GET_CATEGORY_BREADCRUMBS, {
    variables: {
      path: location.pathname,
    },
    skip: location.pathname === `${basename}/categories`,
  });

  if (loading) return <Spinner size={SpinnerSize.SMALL} />;
  if (error) throw error;

  const breadcrumbs = buildBreadcrumbEntries(data?.category?.breadcrumbs, location, projectData?.project);

  const breadcrumbRenderer = (breadcrumbProps: BreadcrumbProps) => (
    <Breadcrumb
      className={styles.breadcrumb}
      href={breadcrumbProps.href}
      text={breadcrumbProps.text}
    />
  );
  const currentBreadcrumbRenderer = (breadcrumbProps: BreadcrumbProps) => (
    <Breadcrumb
      className={styles.currentBreadcrumb}
      text={breadcrumbProps.text}
      current
    />
  );

  return (
    <div className={styles.breadcrumbContainer}>
      <Breadcrumbs
        breadcrumbRenderer={breadcrumbRenderer}
        className={styles.breadcrumbs}
        currentBreadcrumbRenderer={currentBreadcrumbRenderer}
        minVisibleItems={1}
        items={breadcrumbs}
      />
    </div>
  );
};
