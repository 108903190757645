import React from 'react';
import { useFormContext } from 'react-hook-form';

import TagField from 'components/FormFields/TagField';
import TextField from 'components/FormFields/TextField';
import TextArea from 'components/FormFields/TextArea';
import PartNumberPreview from 'components/Part/PartNumberPreview';
import { useLocation } from 'react-router-dom';
import Select2 from 'components/FormFields/Select2';

interface PropertyValueFormData {
  label: string;
  value: string;
}

interface Props {
  fieldsConfig: any;
  categories: PropertyValueFormData[];
  projects: PropertyValueFormData[];
}

export default (props: Props) => {
  const { control } = useFormContext();
  const location = useLocation();
  const onPartCreatePage = /^\/parts\/create/.test(location.pathname);

  return (
    <>
      <Select2
        control={control}
        config={props.fieldsConfig.categoryId}
        options={props.categories}
      />

      <Select2
        control={control}
        config={props.fieldsConfig.projectId}
        options={props.projects}
      />

      {onPartCreatePage && (
        <PartNumberPreview
          fieldsConfig={props.fieldsConfig}
        />
      )}

      <TextField
        control={control}
        config={props.fieldsConfig.shortDescription}
      />

      <TextArea
        control={control}
        config={props.fieldsConfig.longDescription}
      />

      <TagField
        control={control}
        config={props.fieldsConfig.legacyPartNumbers}
      />
    </>
  );
};
