import React from 'react';

import TextField from 'components/FormFields/TextField';
import TextArea from 'components/FormFields/TextArea';

interface Props {
  control: any;
  fieldsConfig: any;
}

export default (props: Props) => {
  return (
    <>
      <TextField
        control={props.control}
        config={props.fieldsConfig.identifier}
      />

      <TextField
        control={props.control}
        config={props.fieldsConfig.name}
      />

      <TextArea
        control={props.control}
        config={props.fieldsConfig.description}
      />
    </>
  );
};
