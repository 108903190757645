import React from 'react';
import { gql, useQuery } from '@apollo/client';
import {
  Button,
  Intent,
  NonIdealState,
} from '@blueprintjs/core';
import { useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';

import Spinner from 'components/Spinner';
import Table from 'components/Table';
import {
  getFullyQualifiedUrl,
} from 'helpers/general';
import useCheckPermission from 'hooks/use-check-permission';

import styles from './index.module.css';

const GET_PART_LIST_DATA = gql`
  query PartListTable($categoryId: Int, $partIds: [Int]) {
    table: partListTable(categoryId: $categoryId, partIds: $partIds) {
      columns
      rows {
        link
        values
      }
    }
  }
`;

interface Props {
  categoryId?: number
  partIds?: number[]
  columns?: any[]
  rows?: any[]
  alwaysOpenInNewTab?: boolean
}

const PartList = (props: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [canAddParts] = useCheckPermission('create_parts');

  const useSuppliedData = !_.isEmpty(props.columns);
  const { loading, error, data } = useQuery(GET_PART_LIST_DATA, {
    variables: {
      categoryId: props.categoryId,
      partIds: props.partIds,
    },
    fetchPolicy: 'network-only', // Always fetch the most up-to-date data
    skip: useSuppliedData,
  });

  const handleRowClick = (link: string, metaKeyPressed: boolean) => {
    if (metaKeyPressed || props.alwaysOpenInNewTab) window.open(getFullyQualifiedUrl(link));
    else navigate(link);
  };

  const rowRenderer = (row: any, rowIndex: number) => {
    return (
      <tr key={`row-${rowIndex}`} onClick={(evt) => handleRowClick(row.link, evt.metaKey)}>
        {row.values.map((value: any, colIndex: number) => <td key={`row-${rowIndex}-cell-${colIndex}`}>{value}</td>)}
      </tr>
    );
  };

  if (loading) return <Spinner />;
  if (error) throw error;

  const columns = props.columns || data.table.columns;
  const rows = props.rows || data.table.rows;

  const renderAction = () => {
    // Not `null` because `<NonIdealState />` expects a React component or `undefined`.
    if (!canAddParts) return undefined;

    return (
      <Button
        icon="clean"
        intent={Intent.PRIMARY}
        onClick={() => navigate(`/parts/create?categoryId=${props.categoryId}`)}
      >
        Add New Part
      </Button>
    );
  };

  // Used in <CategoryDetail />
  if (rows.length === 0 && props.categoryId) {
    return (
      <NonIdealState
        className={styles.empty}
        icon="search"
        title="There's no parts here"
        action={renderAction()}
      />
    );
  }

  return (
    <Table
      classNames={['bp4-interactive', 'bp4-html-table-striped', styles.table]}
      columns={columns}
      rows={rows}
      rowRenderer={rowRenderer}
      sortable
      sticky={{ top: /^\/categories\//.test(location.pathname) ? 40 : 0 }}
    />
  );
};

PartList.defaultProps = {
  categoryId: undefined,
  partIds: undefined,
  rows: undefined,
  columns: undefined,
  alwaysOpenInNewTab: false,
};

export default PartList;
