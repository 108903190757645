import React from 'react';
import { NonIdealState, NonIdealStateProps } from '@blueprintjs/core';

export default (props: NonIdealStateProps) => {
  return (
    <NonIdealState
      icon="error"
      title="Error"
      {...props}
    />
  );
};
