import React from 'react';

import CategoryNav from 'components/CategoryNav';
import CategoryEdit from 'components/Category/CategoryEdit';
import ErrorBoundary from 'components/ErrorBoundary';

export default () => (
  <>
    <CategoryNav />
    <ErrorBoundary>
      <CategoryEdit />
    </ErrorBoundary>
  </>
);
