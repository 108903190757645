import React from 'react';
import { FormGroup, InputGroup } from '@blueprintjs/core';
import { useFormContext, useWatch } from 'react-hook-form';
import { gql, useQuery } from '@apollo/client';
import _ from 'lodash';

import Spinner from 'components/Spinner';
import TextField from 'components/FormFields/TextField';

import styles from './index.module.css';

const GET_PART_NUMBER_PREVIEW = gql`
  query PartNumberPreview($categoryId: Int!, $projectId: Int!) {
    partNumberPreview(categoryId: $categoryId, projectId: $projectId)
  }
`;

interface Props {
  fieldsConfig: any
}

export default (props: Props) => {
  const { control, setValue } = useFormContext();

  const categoryId = useWatch({ control, name: 'categoryId' });
  const projectId = useWatch({ control, name: 'projectId' });
  const properties = useWatch({ control, name: 'properties' });

  const sizeGradedProperty = properties.find((p: any) => p.sizeGraded);
  const sizeGradeIdentifier = sizeGradedProperty?.sizeGrades.find((s: any) => {
    return s.value === sizeGradedProperty.value;
  })?.identifier;

  const missingInfo = !categoryId || !projectId;

  const { loading, error, data } = useQuery(GET_PART_NUMBER_PREVIEW, {
    variables: {
      categoryId: Number(categoryId),
      projectId: Number(projectId),
    },
    skip: missingInfo,
    fetchPolicy: 'network-only',
    onCompleted: (result: any) => {
      const nextAvailableLocalPartNumber = result.partNumberPreview.split('-')[1];
      setValue('partNumber', nextAvailableLocalPartNumber);
    },
  });

  if (missingInfo) return null;

  if (loading) {
    return (
      <div className={styles.container}>
        <Spinner />
      </div>
    );
  }

  // Allows GraphQL errors to be handled by the ErrorBoundary; implementing
  // ManualError here would have the same UX
  if (error) throw error;

  const [
    categoryIdentifier,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    localPartNumber,
    projectIdentifier,
  ] = _.split(data.partNumberPreview, '-');

  /* eslint-disable jsx-a11y/label-has-associated-control */
  return (
    <>
      <h3>Part number preview</h3>

      <div className={styles.previewContainer}>
        <FormGroup
          label={<label className={styles.label}>Category</label>}
        >
          <InputGroup
            disabled
            value={categoryIdentifier}
          />
        </FormGroup>

        <div className={styles.dash}>-</div>

        <FormGroup
          label={<label className={styles.label}>Part #</label>}
        >
          <TextField
            config={props.fieldsConfig.partNumber}
            control={control}
            formGroupProps={{
              className: styles.partNumberFormGroup,
            }}
          />
        </FormGroup>

        <div className={styles.dash}>-</div>

        <FormGroup
          label={<label className={styles.label}>Project</label>}
        >
          <InputGroup
            disabled
            value={projectIdentifier}
          />
        </FormGroup>

        <div className={styles.dash}>-</div>

        <FormGroup
          label={<label className={styles.label}>Size Grade</label>}
        >
          <InputGroup
            disabled
            value={sizeGradeIdentifier}
          />
        </FormGroup>
      </div>
    </>
  );
};
