import { FormGroup, FormGroupProps } from '@blueprintjs/core';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { Controller } from 'react-hook-form';

const handleChange = (e: any, isMultiple: boolean | undefined, field: any) => {
  if (!isMultiple) return field.onChange(e);

  const selectedValues = Array.from(e.target.selectedOptions, (option: any) => option.value);
  return field.onChange({ target: { value: selectedValues } });
};

interface FieldConfig {
  name: string
  label?: string | React.ReactNode
  disabled?: boolean
  multiple?: boolean
}

interface Option {
  label: string
  value: string
}

interface Props {
  control: any
  config: FieldConfig
  formGroupProps?: FormGroupProps,
  options: Option[]
  selectClassName?: string;
}

export const Select = (props: Props) => {
  return (
    <Controller
      name={props.config.name}
      control={props.control}
      render={({ field }) => {
        // Skips check for category filter values (tracked as an array because they are multi-select).
        // Otherwise checks the value like normal.
        const valueIsIncludedInOptions = _.isArray(field.value) || _.chain(props.options)
          .map('value')
          .map(_.toString)
          .includes(_.toString(field.value))
          .value();

        const getValue = () => {
          if (valueIsIncludedInOptions) return field.value;
          if (props.config.multiple) return [];
          return '';
        };

        return (
          <FormGroup
            label={props.config.label}
            labelFor={props.config.name}
            {...props.formGroupProps}
          >
            <div className={classNames('bp4-fill', { 'bp4-html-select': !props.config.multiple })}>
              <select
                id={field.name}
                className={props.selectClassName}
                disabled={props.config.disabled}
                multiple={props.config.multiple}
                value={getValue()}
                onChange={e => handleChange(e, props.config.multiple, field)}
              >
                {(!valueIsIncludedInOptions && !props.config.multiple) && <option value="" disabled>Choose one</option>}
                {props.options.map(({ label, value }) => <option key={value} value={value}>{label}</option>)}
              </select>
              {!props.config.multiple && <span className="bp4-icon bp4-icon-double-caret-vertical" />}
            </div>
          </FormGroup>
        );
      }}
    />
  );
};

Select.defaultProps = {
  formGroupProps: {},
  selectClassName: undefined,
};

export default Select;
