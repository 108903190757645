import React, { useEffect } from 'react';
import {
  gql,
  useMutation,
} from '@apollo/client';
import {
  useForm,
} from 'react-hook-form';
import {
  Icon,
  Intent,
} from '@blueprintjs/core';
import _ from 'lodash';
import {
  useNavigate,
} from 'react-router-dom';

import PropertyFormFields from 'components/Property/PropertyFormFields';
import FormButtons from 'components/FormButtons';
import toaster from 'helpers/toaster';

import styles from './index.module.css';

const CREATE_PROPERTY = gql`
  mutation PropertyCreate($propertyInput: PropertyInput!) {
    propertyCreate(propertyInput: $propertyInput) {
      id
    }
  }
`;

const CREATE_SIZE_GRADE = gql`
  mutation SizeGradeCreate($sizeGradeInput: SizeGradeInput!) {
    sizeGradeCreate(sizeGradeInput: $sizeGradeInput) {
      id
    }
  }
`;

const defaultValues = {
  name: '',
  description: '',
  type: 'NUMBER',
  min: '',
  max: '',
  values: [],
  required: true,
  unit: '',
  useAsSizeGrade: false,
};

const fieldsConfig = {
  name: {
    name: 'name',
    label: 'Name',
    validation: {
      required: true,
    },
  },
  description: {
    name: 'description',
    label: 'Description',
  },
  type: {
    name: 'type',
    label: 'Type',
    validation: {
      required: true,
    },
  },
  min: {
    name: 'min',
    label: 'Min',
    disabled: false,
    validation: {
      required: true,
      pattern: /\d+/,
    },
  },
  max: {
    name: 'max',
    label: 'Max',
    disabled: false,
    validation: {
      required: true,
      pattern: /\d+/,
    },
  },
  unit: {
    name: 'unit',
    label: 'Unit',
  },
  values: {
    name: 'values',
    label: (
      <>
        Values <Icon icon="help" htmlTitle="Fixed values that MUST be chosen when parts are added. Disables free-form text entry (incl. min/max). Press Enter/Return to store a value." />
      </>
    ),
  },
  useAsSizeGrade: {
    name: 'useAsSizeGrade',
    label: 'Use as size grade',
  },
};

const types = [
  { label: 'Number', value: 'NUMBER' },
  { label: 'Text', value: 'STRING' },
];

// TODO: We may want to pull this from the server.
const units = [
  { label: 'None', value: '' },
  { label: 'in', value: 'in' },
  { label: 'mm', value: 'mm' },
  { label: 'cm', value: 'cm' },
];

interface FormData {
  name: string;
  description: string;
  type: string;
  min: string;
  max: string;
  values: string[];
  unit: string;
  useAsSizeGrade: boolean;
}

export default () => {
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    resetField,
  } = useForm<FormData>({ defaultValues });

  const [createProperty, {
    loading: createPropertyLoading,
  }] = useMutation(CREATE_PROPERTY, {
    onCompleted: () => toaster.show({
      intent: Intent.SUCCESS,
      message: 'Property created successfully',
    }),
    onError: ({ message }) => toaster.show({
      intent: Intent.DANGER,
      message: `Error creating property: ${message}`,
    }),
  });

  const [createSizeGrade, {
    loading: createSizeGradeLoading,
  }] = useMutation(CREATE_SIZE_GRADE, {
    onError: ({ message }) => toaster.show({
      intent: Intent.DANGER,
      message: `Error creating size grade(s): ${message}`,
    }),
  });

  // Enables/disables min and max fields depending on which type is selected.
  useEffect(() => {
    const enableMinMaxFields = () => {
      resetField('min');
      resetField('max');
      fieldsConfig.min.disabled = false;
      fieldsConfig.max.disabled = false;
      fieldsConfig.min.validation.required = true;
      fieldsConfig.max.validation.required = true;
    };

    const disableMinMaxFields = () => {
      resetField('min');
      resetField('max');
      fieldsConfig.min.disabled = true;
      fieldsConfig.max.disabled = true;
      fieldsConfig.min.validation.required = false;
      fieldsConfig.max.validation.required = false;
    };

    const subscription = watch((value, { name }) => {
      if (name !== 'type' && name !== 'values') return;

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (value.type === 'STRING' || value.values!.length > 0) disableMinMaxFields();
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      else if (value.type === 'NUMBER' || value.values!.length === 0) enableMinMaxFields();
    });

    return () => subscription.unsubscribe();
  }, [resetField, setValue, watch]);

  const createSizeGrades = async (propertyId: number, values: any[]) => {
    const promises = values.map((value: string, index: number) => {
      const sizeGradeVariables = {
        sizeGradeInput: {
          propertyId,
          identifier: _.padStart(_.toString(index), 2, '0'),
          value,
        },
      };

      return createSizeGrade({ variables: sizeGradeVariables });
    });

    await Promise.all(promises);
  };

  const onSubmit = async (formData: any) => {
    const variables = {
      propertyInput: {
        name: formData.name,
        description: (formData.description === '') ? null : formData.description, // Don't set to empty string if not provided
        type: formData.type,
        min: parseFloat(formData.min),
        max: parseFloat(formData.max),
        values: formData.values,
        unit: (formData.unit === '') ? null : formData.unit, // Don't set to empty string if not provided
      },
    };

    await createProperty({
      variables,
      onCompleted: (data) => {
        if (formData.useAsSizeGrade) createSizeGrades(data.propertyCreate.id, formData.values);
      },
    });

    navigate('/properties');
  };

  return (
    <div className={styles.container}>
      <h1 className="bp4-heading">Add Property</h1>
      <form className={styles.form}>
        <PropertyFormFields
          control={control}
          fieldsConfig={fieldsConfig}
          types={types}
          units={units}
        />

        <FormButtons
          submitHandler={handleSubmit(onSubmit)}
          disabled={createPropertyLoading || createSizeGradeLoading}
        />
      </form>
    </div>
  );
};
