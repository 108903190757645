import React, { useEffect } from 'react';
import {
  useLocation, useNavigate,
} from 'react-router-dom';
import {
  gql,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  useForm,
  useFieldArray,
  FormProvider,
} from 'react-hook-form';
import {
  Callout,
  Intent,
} from '@blueprintjs/core';
import classNames from 'classnames';
import _ from 'lodash';
import { marked } from 'marked';

import Spinner from 'components/Spinner';
import toaster from 'helpers/toaster';
import PartFormFields from 'components/Part/PartFormFields';
import PartPropertyFormFields from 'components/Part/PartPropertyFormFields';
import FormButtons from 'components/FormButtons';

import styles from './index.module.css';

const GET_PART = gql`
  query PartByGlobalPartNumber($globalPartNumber: String!) {
    part: partByGlobalPartNumber(globalPartNumber: $globalPartNumber) {
      id
      shortDescription
      longDescription
      partNumber
      globalPartNumber
      category {
        id
        identifier
        name
        hierarchy
        instructions
      }
      project {
        id
        identifier
        name
      }
      rootPart {
        id
      }
      partNumber
      globalPartNumber
      legacyPartNumbers
      properties {
        partPropertyId
        value
        categoryProperty {
          id
          values
          sizeGraded
          sizeGrades {
            id
            identifier
            value
          }
          required
          property {
            id
            name
            type
            unit
            min
            max
            values
          }
        }
      }
    }
  }
`;

const UPDATE_PART = gql`
  mutation PartUpdate($partId: Int!, $partInput: PartInput!, $propertyInputs: [PartPropertyInput]) {
    partUpdate(partId: $partId, partInput: $partInput, propertyInputs: $propertyInputs) {
      id
    }
  }
`;

const defaultValues = {
  partNumber: '',
  shortDescription: '',
  longDescription: '',
  categoryId: '',
  projectId: '',
  legacyPartNumbers: [],
  properties: [],
};

const fieldsConfig = {
  partNumber: {
    name: 'partNumber',
    disabled: true,
  },
  shortDescription: {
    name: 'shortDescription',
    label: 'Short description',
    validation: {
      required: true,
      maxLength: 50,
    },
    validationMessages: {
      maxLength: 'Must be less than 50 characters',
    },
  },
  longDescription: {
    name: 'longDescription',
    label: 'Long description',
    validation: {
      maxLength: 100,
    },
    validationMessages: {
      maxLength: 'Must be less than 100 characters',
    },
  },
  categoryId: {
    name: 'categoryId',
    label: 'Category',
    disabled: true,
  },
  projectId: {
    name: 'projectId',
    label: 'Project',
    disabled: true,
  },
  legacyPartNumbers: {
    name: 'legacyPartNumbers',
    label: 'Legacy part numbers',
  },
};

interface PropertyValueFormData {
  label: string;
  value: string;
}

// TODO: Validate this because I needed to add a bunch of optionals to get it working again.
interface PropertyFormData {
  partId?: number;
  propertyId?: number;
  categoryPropertyId: number;
  name: string;
  label: string;
  value: string;
  min?: number;
  max?: number;
  type?: string;
  values?: any[];
  sizeGraded?: boolean;
  sizeGrades?: any[];
  required?: boolean;
  usingOverrides?: boolean;
  partPropertyId?: number;
  defaultOptions?: PropertyValueFormData[],
  overrideOptions?: PropertyValueFormData[] | null,
}

interface FormData {
  partNumber: string;
  shortDescription: string;
  longDescription: string;
  categoryId: string;
  projectId: string;
  legacyPartNumbers: string[];
  properties: PropertyFormData[];
}

const formatCategory = (category: any): PropertyValueFormData[] => {
  return [{
    label: `${category.name} (${category.identifier}) (${category.hierarchy})`,
    value: category.id,
  }];
};

const formatProject = (project: any): PropertyValueFormData[] => {
  return [{
    label: `${project.name} (${project.identifier})`,
    value: project.id,
  }];
};

const buildPropertyFormLabel = (name: string, unit: string | null) => {
  if (unit === null) return name;
  return `${name} (${unit})`;
};

const generatePropertyFormData = (properties: [], partId: number) => {
  return properties.map((property: any) => {
    const defaultOptions = property.categoryProperty.property.values.map((value: string) => ({ value }));
    const overrideOptions = property.categoryProperty.values?.map((value: string) => ({ value })) ?? [];
    let values = defaultOptions;
    let usingOverrides = false;
    if (overrideOptions.length > 0) {
      values = overrideOptions;
      usingOverrides = true;
    }
    return {
      partId,
      propertyId: property.categoryProperty.property.id,
      categoryPropertyId: property.categoryProperty.id,
      partPropertyId: property.partPropertyId,
      name: property.categoryProperty.property.name,
      label: buildPropertyFormLabel(property.categoryProperty.property.name, property.categoryProperty.property.unit),
      value: property.value,
      min: property.categoryProperty.min ?? property.categoryProperty.property.min,
      max: property.categoryProperty.max ?? property.categoryProperty.property.max,
      type: property.categoryProperty.property.type,
      values,
      sizeGraded: property.categoryProperty.sizeGraded,
      sizeGrades: property.categoryProperty.sizeGrades,
      required: property.categoryProperty.required,
      usingOverrides,
    };
  });
};

const generatePartPropertyUpdates = (originalPart: any, formStateProperties: any[]) => {
  const originalPropertiesSortedByLabel = _.sortBy(originalPart.properties, 'categoryProperty.property.name'); // Alphabetically sort by label
  const formStatePropertiesSortedByLabel = _.sortBy(formStateProperties, 'label'); // Alphabetically sort by label

  return originalPropertiesSortedByLabel
    .reduce((acc: any, originalProperty: any, index: number) => {
      const originalValue = originalProperty.value;
      const modifiedValue = formStatePropertiesSortedByLabel[index].value;

      if (modifiedValue === originalValue) return acc; // Value unchanged so bail out

      const obj = {
        partPropertyId: originalProperty.partPropertyId,
        partId: originalPart.id,
        categoryPropertyId: originalProperty.categoryProperty.id,
        value: modifiedValue,
      };

      acc.push(obj);

      return acc;
    }, []);
};

const renderInstructions = (instructions: string) => {
  if (!instructions) return null;

  return (
    <Callout
      title="Instructions"
      icon="info-sign"
      intent="primary"
      className={styles.instructions}
    >
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: marked(instructions) }}
      />
    </Callout>
  );
};

export default () => {
  const location = useLocation();
  const navigate = useNavigate();
  const globalPartNumber = location.pathname.replace('/parts/edit/', '');
  const form = useForm<FormData>({ defaultValues });
  const {
    handleSubmit,
    control,
    setValue,
  } = form;
  const {
    fields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'properties',
  });

  const { loading, error, data } = useQuery(GET_PART, { variables: { globalPartNumber } });

  const [updatePart, {
    loading: updatePartLoading,
  }] = useMutation(UPDATE_PART, {
    onCompleted: () => toaster.show({
      intent: Intent.SUCCESS,
      message: 'Part updated successfully',
    }),
    onError: ({ message }) => {
      toaster.show({
        intent: Intent.DANGER,
        message: `Error updating part: ${message}`,
      });
    },
  });

  // Initialize form fields
  useEffect(() => {
    if (!data) return;

    setValue('partNumber', data.part.partNumber);
    setValue('shortDescription', data.part.shortDescription || '');
    setValue('longDescription', data.part.longDescription || '');
    setValue('categoryId', data.part.category.id);
    setValue('projectId', data.part.project.id);
    setValue('legacyPartNumbers', data.part.legacyPartNumbers);

    const properties = generatePropertyFormData(data.part.properties, data.part.id)
      .sort((a: any, b: any) => { // Alphabetically sort by label
        if (a.label > b.label) return 1;
        if (a.label < b.label) return -1;
        return 0;
      });

    // Prevents dynamic form from appending the same property form field multiple times.
    // Does not lose the modified values.
    remove();
    append(properties);
  }, [append, data, remove, setValue]);

  const onSubmit = async (formData: any) => {
    await updatePart({
      variables: {
        partId: data.part.id,
        partInput: {
          rootPartId: data.part.rootPart?.id ?? null,
          categoryId: Number(formData.categoryId),
          projectId: Number(formData.projectId),
          partNumber: data.part.partNumber,
          legacyPartNumbers: formData.legacyPartNumbers,
          shortDescription: formData.shortDescription,
          longDescription: formData.longDescription,
        },
        propertyInputs: generatePartPropertyUpdates(data.part, formData.properties),
      },
    });

    navigate(`/parts/${globalPartNumber}`);
  };

  if (loading) return <Spinner />;
  if (error) throw error;

  return (
    <div className={styles.container}>
      <h1 className="bp4-heading">Edit Part #{globalPartNumber}</h1>

      <FormProvider {...form}>
        <form className={styles.form}>
          {renderInstructions(data.part.category.instructions)}

          <PartFormFields
            fieldsConfig={fieldsConfig}
            categories={formatCategory(data.part.category)}
            projects={formatProject(data.part.project)}
          />

          <h2 className={classNames('bp4-heading', styles.propertiesHeading)}>Properties</h2>
          <PartPropertyFormFields
            categoryId={data.part.category.id}
            partId={data.part.id}
            partNumber={data.part.partNumber}
            fields={fields}
          />

          <FormButtons
            submitHandler={handleSubmit(onSubmit)}
            disabled={updatePartLoading}
          />
        </form>
      </FormProvider>
    </div>
  );
};
