import React from 'react';

import CategoryNav from 'components/CategoryNav';
import CategoryCreate from 'components/Category/CategoryCreate';
import ErrorBoundary from 'components/ErrorBoundary';

export default () => (
  <>
    <CategoryNav />
    <ErrorBoundary>
      <CategoryCreate />
    </ErrorBoundary>
  </>
);
