import React from 'react';
import {
  gql,
  useQuery,
} from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { Button, Tag } from '@blueprintjs/core';
import classNames from 'classnames';

import Spinner from 'components/Spinner';
import Table from 'components/Table';
import {
  getFullyQualifiedUrl,
  PropertyTypeMap,
} from 'helpers/general';
import useCheckPermission from 'hooks/use-check-permission';

import styles from './index.module.css';

const GET_PROPERTIES = gql`
  query Properties {
    properties {
      id
      name
      description
      type
      min
      max
      values
      unit
    }
  }
`;

const columns = [
  'Name',
  'Description',
  'Type',
  'Unit',
  'Min',
  'Max',
  'Values',
];

const formatRow = (property: any) => {
  const propertyValueTags = property.values.map((v: string, i: number) => (
    <Tag key={`${property.id}-${i}`}>{v}</Tag>
  ));
  return {
    link: `/properties/${property.id}`,
    values: [
      property.name,
      property.description,
      PropertyTypeMap[property.type] ?? 'UNKNOWN',
      property.unit,
      property.min,
      property.max,
      propertyValueTags,
    ],
  };
};

export default () => {
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(GET_PROPERTIES, {
    fetchPolicy: 'cache-and-network',
  });

  const [canAddProperties] = useCheckPermission('create_properties');

  const handleRowClick = (link: string, metaKeyPressed: boolean) => {
    if (metaKeyPressed) window.open(getFullyQualifiedUrl(link));
    else navigate(link);
  };

  const rowRenderer = (row: any, rowIndex: number) => {
    return (
      <tr key={`row-${rowIndex}`} onClick={(evt) => handleRowClick(row.link, evt.metaKey)}>
        {row.values.map((value: string, colIndex: number) => (
          <td
            key={`row-${rowIndex}-col-${colIndex}`}
            className={styles[`cell${columns[colIndex]}`]}
          >
            <div className={styles[`cellInner${columns[colIndex]}`]}>
              {value}
            </div>
          </td>
        ))}
      </tr>
    );
  };

  const renderAddButton = () => {
    if (!canAddProperties) return null;

    return (
      <Button
        icon="plus"
        onClick={() => navigate('/properties/create')}
      >
        Add Property
      </Button>
    );
  };

  if (loading) return <Spinner />;
  if (error) throw error;

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h1 className={classNames(styles.heading, 'bp4-heading')}>Properties</h1>
        {renderAddButton()}
      </header>

      <div className={styles.tableWrapper}>
        <Table
          columns={columns}
          rows={data.properties.map(formatRow)}
          rowRenderer={rowRenderer}
          classNames={['bp4-interactive', 'bp4-html-table-striped', styles.table]}
          sticky={{ top: 0 }}
        />
      </div>
    </div>
  );
};
