import React, { useEffect } from 'react';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import {
  gql,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  useForm,
} from 'react-hook-form';
import {
  Intent,
} from '@blueprintjs/core';

import ProjectFormFields from 'components/Project/ProjectFormFields';
import FormButtons from 'components/FormButtons';
import Spinner from 'components/Spinner';
import toaster from 'helpers/toaster';

import styles from './index.module.css';

const GET_PROJECT = gql`
  query ProjectById($projectId: Int!) {
    project: projectById(projectId: $projectId) {
      id
      identifier
      name
      description
    }
  }
`;

const UPDATE_PROJECT = gql`
  mutation ProjectUpdate($projectId: Int!, $projectInput: ProjectInput!) {
    projectUpdate(projectId: $projectId, projectInput: $projectInput) {
      id
    }
  }
`;

const defaultValues = {
  identifier: '',
  name: '',
  description: '',
};

const fieldsConfig = {
  identifier: {
    name: 'identifier',
    label: 'Identifier',
    validation: {
      required: true,
      pattern: /\d{3}/,
    },
    validationMessages: {
      pattern: 'Must be 3 digits',
    },
  },
  name: {
    name: 'name',
    label: 'Name',
    validation: {
      required: true,
    },
  },
  description: {
    name: 'description',
    label: 'Description',
  },
};

interface FormData {
  identifier: string;
  name: string;
  description: string;
}

export default () => {
  const navigate = useNavigate();
  const params = useParams();
  const projectId = Number(params.projectId);
  const {
    handleSubmit,
    control,
    setValue,
  } = useForm<FormData>({ defaultValues });

  const { loading, error, data } = useQuery(GET_PROJECT, { variables: { projectId } });

  const [updateProject, {
    loading: updateProjectLoading,
  }] = useMutation(UPDATE_PROJECT, {
    refetchQueries: [
      { query: GET_PROJECT, variables: { projectId } },
    ],
    onCompleted: () => {
      toaster.show({ intent: Intent.SUCCESS, message: 'Project updated successfully' });
      navigate(`/projects/${projectId}`);
    },
    onError: ({ message }) => {
      toaster.show({
        intent: Intent.DANGER,
        message: `Error updating project: ${message}`,
      });
    },
  });

  // Initialize form fields
  useEffect(() => {
    if (!data) return;

    setValue('identifier', data.project.identifier);
    setValue('name', data.project.name);
    setValue('description', data.project.description || '');
  }, [data, setValue]);

  if (loading) return <Spinner />;
  if (error) throw error;

  const onSubmit = async (formData: any) => {
    const variables = {
      projectId: data.project.id,
      projectInput: {
        identifier: formData.identifier,
        name: formData.name,
        description: formData.description,
      },
    };

    await updateProject({ variables });
  };

  return (
    <div className={styles.container}>
      <h1 className="bp4-heading">Edit {data.project.name} Project</h1>
      <form className={styles.form}>
        <ProjectFormFields
          control={control}
          fieldsConfig={fieldsConfig}
        />

        <FormButtons
          submitHandler={handleSubmit(onSubmit)}
          disabled={updateProjectLoading}
        />
      </form>
    </div>
  );
};
