import React from 'react';
import {
  Checkbox,
} from '@blueprintjs/core';
import { Controller } from 'react-hook-form';

interface FieldConfig {
  name: string;
  label?: string;
  disabled?: boolean;
}

interface Props {
  control: any;
  config: FieldConfig;
}

export default (props: Props) => {
  return (
    <Controller
      name={props.config.name}
      control={props.control}
      render={({ field }) => {
        return (
          <Checkbox
            onChange={field.onChange}
            checked={field.value}
            label={props.config.label}
            disabled={props.config.disabled}
          />
        );
      }}
    />
  );
};
