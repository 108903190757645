import React, { useEffect, useState } from 'react';
import {
  useLocation, useNavigate,
} from 'react-router-dom';
import {
  gql,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  useForm,
  useFieldArray,
  FormProvider,
} from 'react-hook-form';
import {
  Alert,
  Intent,
} from '@blueprintjs/core';
import _ from 'lodash';

import toaster from 'helpers/toaster';
import CategoryFormFields from 'components/Category/CategoryFormFields';
import FormButtons from 'components/FormButtons';
import Spinner from 'components/Spinner';
import ManualError from 'components/ManualError';
import useCheckPermission from 'hooks/use-check-permission';
import AddPropertyDropdown from './AddPropertyDropdown';
import CategoryPropertyEditTable from './CategoryPropertyEditTable';

import styles from './index.module.css';

const GET_CATEGORY_BY_PATH = gql`
  query CategoryByPath($path: String!) {
    category: categoryByPath(path: $path) {
      id
      identifier
      path
      name
      description
      instructions
      subcategoryCount
      parentCategory {
        id
      }
      categoryProperties {
        id
        description
        min
        max
        values
        sizeGraded
        sizeGrades {
          id
          identifier
          value
        }
        visible
        required
        ordinality
        property {
          id
          name
          description
          type
          min
          max
          values
          unit
        }
      }
    }
  }
`;

const UPDATE_CATEGORY = gql`
  mutation UpdateCategory($categoryId: Int!, $categoryInput: CategoryInput!) {
    categoryUpdate(categoryId: $categoryId, categoryInput: $categoryInput) {
      id
      path
    }
  }
`;

const BATCH_UPDATE_CATEGORY_PROPERTY = gql`
  mutation BatchUpdateCategoryProperty($categoryPropertyInputs: [CategoryPropertyInput]!) {
    categoryPropertyBatchUpdate(categoryPropertyInputs: $categoryPropertyInputs) {
      id
    }
  }
`;

const CREATE_CATEGORY_PROPERTY = gql`
  mutation CategoryPropertyCreate($categoryPropertyInput: CategoryPropertyInput!) {
    categoryPropertyCreate(categoryPropertyInput: $categoryPropertyInput) {
      id
    }
  }
`;

const DELETE_CATEGORY_PROPERTY = gql`
  mutation DeleteCategoryProperty($categoryPropertyId: Int!) {
    categoryPropertyRemove(categoryPropertyId: $categoryPropertyId)
  }
`;

// The frontend expects values to be `''` if they aren't set, so convert from `null` which the
// backend uses.
const generateCategoryPropertyFormData = (category: any) => {
  return category.categoryProperties.map((categoryProperty: any) => ({
    categoryPropertyId: categoryProperty.id,
    categoryId: category.id,
    description: categoryProperty.description || '',
    min: categoryProperty.min || '',
    max: categoryProperty.max || '',
    values: categoryProperty.values || [],
    sizeGraded: categoryProperty.sizeGraded,
    sizeGrades: categoryProperty.sizeGrades || [],
    visible: categoryProperty.visible,
    required: categoryProperty.required,
    ordinality: categoryProperty.ordinality,
    property: categoryProperty.property,
  }));
};

const generateCategoryPropertyUpdates = (originalCategory: any, formStateProperties: any[]) => {
  const originalCategoryPropertiesSortedById = _.sortBy(originalCategory.categoryProperties, 'id');
  const formStateCategoryPropertiesSortedById = _.sortBy(formStateProperties, 'categoryPropertyId');

  return originalCategoryPropertiesSortedById
    .reduce((acc: any, originalCategoryProperty: any, index: number) => {
      const formStateProperty = formStateCategoryPropertiesSortedById[index];

      // Fallbacks account for how the form initializes in `generateCategoryPropertyFormData()`
      const original = {
        description: originalCategoryProperty.description || '',
        min: originalCategoryProperty.min || '',
        max: originalCategoryProperty.max || '',
        values: originalCategoryProperty.values || [],
        sizeGraded: originalCategoryProperty.sizeGraded,
        visible: originalCategoryProperty.visible,
        required: originalCategoryProperty.required,
        ordinality: originalCategoryProperty.ordinality,
      };

      const modified = {
        description: formStateProperty.description,
        min: formStateProperty.min,
        max: formStateProperty.max,
        values: formStateProperty.values,
        sizeGraded: formStateProperty.sizeGraded,
        visible: formStateProperty.visible,
        required: formStateProperty.required,
        ordinality: formStateProperty.ordinality,
      };

      if (_.isEqual(modified, original)) return acc; // Unchanged so bail out

      const obj = {
        id: originalCategoryProperty.id,
        categoryId: originalCategory.id,
        propertyId: originalCategoryProperty.property.id,
        description: (modified.description === '') ? null : modified.description,
        min: (modified.min === '') ? null : parseFloat(modified.min),
        max: (modified.max === '') ? null : parseFloat(modified.max),
        values: modified.values,
        sizeGraded: modified.sizeGraded,
        visible: modified.visible,
        required: modified.required,
        ordinality: parseInt(modified.ordinality, 10),
      };

      acc.push(obj);

      return acc;
    }, []);
};

const defaultValues = {
  name: '',
  identifier: '',
  description: '',
  properties: [],
};

const fieldsConfig = {
  name: {
    name: 'name',
    label: 'Name',
    validation: {
      required: true,
    },
  },
  identifier: {
    name: 'identifier',
    label: 'Identifier',
    validation: {
      required: true,
      minLength: 3,
      maxLength: 5,
    },
    validationMessages: {
      minLength: 'Must be at least 3 characters',
      maxLength: 'Must be at most 5 characters',
    },
  },
  description: {
    name: 'description',
    label: 'Description',
  },
  instructions: {
    name: 'instructions',
    label: 'Instructions',
  },
};

interface PropertyValueFormData {
  label: string;
  value: string;
}

interface PropertyFormData {
  categoryId?: number;
  propertyId?: number;
  min: string | null;
  max: string | null;
  values?: string[];
  property?: any;
  partId?: number;
  categoryPropertyId?: number;
  partPropertyId?: number;
  name?: string;
  identifier?: string;
  description?: string;
  visible: boolean;
  required: boolean;
  ordinality: number;
  defaultOptions?: PropertyValueFormData[],
  overrideOptions?: PropertyValueFormData[] | null,
}

interface FormData {
  name: string;
  identifier: string;
  description: string;
  instructions: string;
  properties: PropertyFormData[];
}

export default () => {
  const [deleteCategoryPropertyIndex, setDeleteCategoryPropertyIndex] = useState<number | null>(null);
  const [deleteCategoryPropertyAlertIsOpen, setDeleteCategoryPropertyAlertIsOpen] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const form = useForm<FormData>({ defaultValues });
  const {
    fields,
    append,
    remove,
  } = useFieldArray({
    control: form.control,
    name: 'properties',
  });

  const [canAddCategoryProperties] = useCheckPermission('create_category_properties');
  const [canEditCategoryProperties] = useCheckPermission('update_category_properties');
  const [canDeleteCategoryProperties] = useCheckPermission('delete_category_properties');

  const path = pathname.replace('/categories/edit', '');

  const {
    loading: categoryLoading,
    error: categoryError,
    data: categoryData,
  } = useQuery(GET_CATEGORY_BY_PATH, {
    variables: { path },
    fetchPolicy: 'network-only', // Prevents <CategoryPropertyEditTable /> from being provided stale data
  });

  const [updateCategory, {
    loading: updateCategoryLoading,
  }] = useMutation(UPDATE_CATEGORY, {
    onCompleted: () => {
      toaster.show({
        intent: Intent.SUCCESS,
        message: 'Category updated successfully',
      });
    },
    onError: ({ message }) => toaster.show({
      intent: Intent.DANGER,
      message: `Error updating category: ${message}`,
    }),
  });

  const [batchUpdateCategoryProperty, {
    loading: batchUpdateCategoryPropertyLoading,
    error: batchUpdateCategoryPropertyError,
  }] = useMutation(BATCH_UPDATE_CATEGORY_PROPERTY, {
    onError: ({ message }) => {
      toaster.show({
        intent: Intent.DANGER,
        message: `Error updating category properties: ${message}`,
      });
    },
  });

  const [createCategoryProperty, {
    loading: createCategoryPropertyLoading,
    error: createCategoryPropertyError,
  }] = useMutation(CREATE_CATEGORY_PROPERTY, {
    onError: ({ message }) => toaster.show({
      intent: Intent.DANGER,
      message: `Error creating category property: ${message}`,
    }),
  });

  const [deleteCategoryProperty] = useMutation(DELETE_CATEGORY_PROPERTY, {
    ignoreResults: true,
    onCompleted: () => {
      toaster.show({
        intent: Intent.SUCCESS,
        message: 'Successfully deleted category property',
      });
    },
    onError: ({ message }) => toaster.show({
      intent: Intent.DANGER,
      message: `Error deleting category property: ${message}`,
    }),
  });

  // Initialize form state
  useEffect(() => {
    if (!categoryData) return;

    form.setValue('name', categoryData.category.name);
    form.setValue('identifier', categoryData.category.identifier);
    form.setValue('description', categoryData.category.description || '');
    form.setValue('instructions', categoryData.category.instructions || '');

    // If category is a leaf category
    if (categoryData.category.subcategoryCount === 0) {
      const properties = generateCategoryPropertyFormData(categoryData.category);

      // Prevents dynamic form from appending the same property form field multiple times.
      // Does not lose the modified values.
      remove();
      append(properties);
    }
  }, [append, categoryData, remove, form]);

  const onAddPropertySelect = (property: any) => {
    append({
      categoryId: categoryData.category.id,
      property,
      propertyId: property.id,
      description: '',
      min: '',
      max: '',
      values: [],
      ordinality: fields.length + 1,
      visible: true,
      required: true,
    });
  };

  const onCategoryPropertyDelete = async () => {
    if (!deleteCategoryPropertyIndex) return;

    const field = fields[deleteCategoryPropertyIndex];

    if (field.categoryPropertyId) {
      await deleteCategoryProperty({
        variables: {
          categoryPropertyId: field.categoryPropertyId,
        },
      });
      setDeleteCategoryPropertyAlertIsOpen(false);
    }

    remove(deleteCategoryPropertyIndex);
  };

  const onSubmit = async (formData: any) => {
    const categoryUpdateResult: any = await updateCategory({
      variables: {
        categoryId: categoryData.category.id,
        categoryInput: {
          parentCategoryId: categoryData.category?.parentCategory?.id ?? null,
          identifier: formData.identifier,
          name: formData.name,
          description: formData.description,
          instructions: formData.instructions,
        },
      },
    });

    // Bail out if the category update doesn't work. E.g. there's a conflict with the identifier.
    if (categoryUpdateResult.errors) return;

    const updateProperties = formData.properties.filter((p: any) => p.categoryPropertyId);
    const categoryPropertyInputs = generateCategoryPropertyUpdates(categoryData.category, updateProperties);
    try {
      await batchUpdateCategoryProperty({
        variables: {
          categoryPropertyInputs,
        },
        // We don't care about the data returned from the mutation, just that it finished loading
        // and that there weren't any errors.
        ignoreResults: true,
      });
    } catch (e) { /* Nothing to do */ }

    const newProperties = formData.properties.filter((p: any) => !p.categoryPropertyId);
    for (let i = 0; i < newProperties.length; i++) {
      const property = newProperties[i];
      await createCategoryProperty({ // eslint-disable-line no-await-in-loop
        variables: {
          categoryPropertyInput: {
            categoryId: property.categoryId,
            propertyId: property.propertyId,
            description: property.description || null,
            min: property.min !== '' ? parseFloat(property.min) : null,
            max: property.max !== '' ? parseFloat(property.max) : null,
            values: property.values || null,
            ordinality: property.ordinality,
          },
        },
      });
    }

    if (!batchUpdateCategoryPropertyError && !createCategoryPropertyError) {
      navigate(`/categories/${categoryUpdateResult.data.categoryUpdate.path}`);
    }
  };

  return (
    <div className={styles.container}>
      {categoryLoading && <Spinner />}
      {categoryError && <ManualError description={categoryError.message} />}
      {!categoryLoading && !categoryError && (
        <>
          <h1 className="bp4-heading">{categoryData.category.name}</h1>

          <FormProvider {...form}>
            <form className={styles.form}>
              <div className={styles.categoryFields}>
                <CategoryFormFields
                  fieldsConfig={fieldsConfig}
                />
              </div>

              {/* Show category property form if category is a leaf category */}
              {categoryData.category.subcategoryCount === 0 && (
                <div className={styles.properties}>
                  <h2 className="bp4-heading">Property Overrides</h2>
                  {canAddCategoryProperties && (
                    <AddPropertyDropdown
                      onAddPropertySelect={onAddPropertySelect}
                    />
                  )}
                  {canEditCategoryProperties && (
                    <div className={styles.propertyEditTableContainer}>
                      <CategoryPropertyEditTable
                        fields={fields}
                        onDelete={index => {
                          setDeleteCategoryPropertyIndex(index);
                          setDeleteCategoryPropertyAlertIsOpen(true);
                        }}
                      />
                    </div>
                  )}
                  {canDeleteCategoryProperties && (
                    <Alert
                      isOpen={deleteCategoryPropertyAlertIsOpen}
                      canEscapeKeyCancel
                      canOutsideClickCancel
                      cancelButtonText="Cancel"
                      confirmButtonText="Delete"
                      intent={Intent.DANGER}
                      icon="trash"
                      onConfirm={() => onCategoryPropertyDelete()}
                      onCancel={() => setDeleteCategoryPropertyAlertIsOpen(false)}
                    >
                      Are you sure you want to delete this category property? All associated
                      part values in this category will also be deleted. This cannot be undone.
                    </Alert>
                  )}
                </div>
              )}

              <FormButtons
                submitHandler={form.handleSubmit(onSubmit)}
                disabled={updateCategoryLoading || batchUpdateCategoryPropertyLoading || createCategoryPropertyLoading}
              />
            </form>
          </FormProvider>
        </>
      )}
    </div>
  );
};
