import React, { useState } from 'react';
import {
  useLocation,
  useNavigate,
} from 'react-router-dom';
import {
  gql,
  useMutation,
  useQuery,
} from '@apollo/client';
import { Alert, Button, ButtonGroup, Intent } from '@blueprintjs/core';
import classNames from 'classnames';

import Spinner from 'components/Spinner';
import Table from 'components/Table';
import useCheckPermission from 'hooks/use-check-permission';
import toaster from 'helpers/toaster';
import PartDetailProperties from './PartDetailProperties';

import styles from './index.module.css';

const GET_PART = gql`
  query PartByGlobalPartNumber($globalPartNumber: String!) {
    part: partByGlobalPartNumber(globalPartNumber: $globalPartNumber) {
      id
      shortDescription
      longDescription
      globalPartNumber
      category {
        id
        name
        path
      }
      project {
        id
        identifier
        name
      }
      partNumber
      globalPartNumber
      legacyPartNumbers
      categoryHierarchy
      properties {
        partPropertyId
        value
        categoryProperty {
          property {
            id
            name
            unit
          }
        }
      }
    }
  }
`;

const DELETE_PART = gql`
  mutation DeletePart($partId: Int!) {
    partRemove(partId: $partId)
  }
`;

const columns = [
  'Field',
  'Value',
];

const formatRows = (part: any) => {
  return [
    ['Global part number', part.globalPartNumber],
    ['Local part number', part.partNumber],
    ['Legacy part numbers', part.legacyPartNumbers.join(', ')],
    ['Short description', part.shortDescription],
    ['Long description', part.longDescription],
    ['Category', `${part.category.name} (${part.categoryHierarchy})`],
    ['Project', part.project.name],
  ];
};

const rowRenderer = (values: string[], rowIndex: number) => {
  return (
    <tr key={`row-${rowIndex}`}>
      {values.map((value, colIndex) => (
        <td
          key={`row-${rowIndex}-col-${colIndex}`}
          className={styles[`cell${columns[colIndex]}`]}
        >
          {value}
        </td>
      ))}
    </tr>
  );
};

export default () => {
  const [deleteAlertIsOpen, setDeleteAlertIsOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const globalPartNumber = location.pathname.replace('/parts/', '');

  const [canEditParts] = useCheckPermission('update_parts');
  const [canCreateParts] = useCheckPermission('create_parts');
  const [canDeleteParts] = useCheckPermission('delete_parts');

  const { loading, error, data } = useQuery(GET_PART, {
    variables: { globalPartNumber },
    fetchPolicy: 'network-only', // Ensures that the latest property values are fetched after update
  });

  const [deletePart] = useMutation(DELETE_PART, {
    ignoreResults: true,
    onCompleted: () => {
      toaster.show({
        intent: Intent.SUCCESS,
        message: `Successfully deleted part #${data.part.globalPartNumber}`,
      });
      navigate(`/categories/${data.part.category.path}`);
    },
    onError: ({ message }) => toaster.show({
      intent: Intent.DANGER,
      message: `Error deleting part: ${message}`,
    }),
  });

  const onConfirmDeleteClick = async () => {
    await deletePart({ variables: { partId: data.part.id } });
  };

  const renderEditPartButtons = () => {
    if (!canEditParts && !canCreateParts && !canDeleteParts) return null;

    return (
      <ButtonGroup>
        {canEditParts && (
          <Button
            icon="edit"
            onClick={() => navigate(`/parts/edit/${globalPartNumber}`)}
          >
            Edit
          </Button>
        )}
        {canCreateParts && (
          <Button
            icon="plus"
            onClick={() => navigate(`/parts/create?categoryId=${data.part.category.id}&rootPartId=${data.part.id}`)}
          >
            Duplicate
          </Button>
        )}
        {canDeleteParts && (
          <>
            <Alert
              isOpen={deleteAlertIsOpen}
              canEscapeKeyCancel
              canOutsideClickCancel
              cancelButtonText="Cancel"
              confirmButtonText="Delete"
              intent={Intent.DANGER}
              icon="trash"
              onConfirm={onConfirmDeleteClick}
              onCancel={() => setDeleteAlertIsOpen(false)}
            >
              Are you sure you want to delete this part? Once deleted, this
              cannot be restored.
            </Alert>
            <Button
              icon="trash"
              onClick={() => setDeleteAlertIsOpen(true)}
            >
              Delete
            </Button>
          </>
        )}
      </ButtonGroup>
    );
  };

  if (loading) return <Spinner />;
  if (error) throw error;

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h1 className={classNames('bp4-heading', styles.heading)}>Part #{globalPartNumber} Detail</h1>
        {renderEditPartButtons()}
      </header>

      <h2 className="bp4-heading">Details</h2>
      <Table
        classNames={[styles.table]}
        columns={columns}
        rows={formatRows(data.part)}
        rowRenderer={rowRenderer}
      />

      {data.part.properties.length > 0 && (
        <>
          <h2 className={classNames('bp4-heading', styles.propertiesHeading)}>Part Properties</h2>
          <PartDetailProperties
            properties={data.part.properties}
          />
        </>
      )}
    </div>
  );
};
