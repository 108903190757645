import React from 'react';
import { Controller } from 'react-hook-form';
import {
  FormGroup,
  TextArea,
} from '@blueprintjs/core';
import classNames from 'classnames';

import ValidationMessage from './ValidationMessage';

interface FieldConfig {
  name: string
  label: string
  validation?: any
  validationMessages?: any
}

interface Props {
  control: any
  config: FieldConfig
}

const showValidationMessage = (formState: any, name: string, validationMessages: any) => {
  const type = formState.errors[name]?.type;
  if (!type) return null;

  if (type === 'required') return <ValidationMessage message="Required" />;

  return <ValidationMessage message={validationMessages[type]} />;
};

export default (props: Props) => {
  return (
    <Controller
      name={props.config.name}
      control={props.control}
      rules={props.config.validation}
      render={({ field, formState }) => {
        const hasErrors = Boolean(formState.errors[props.config.name]);
        return (
          <FormGroup
            className={classNames({ 'bp4-intent-danger': hasErrors })}
            label={props.config.label}
            labelFor={`input-${props.config.name}`}
          >
            <TextArea
              id={`input-${props.config.name}`}
              {...field}
              value={(field.value === null) ? '' : field.value}
              fill
            />
            {showValidationMessage(formState, props.config.name, props.config.validationMessages)}
          </FormGroup>
        );
      }}
    />
  );
};
