import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Button,
  Classes,
  Dialog,
  Icon,
  Intent,
  PopoverPosition,
  Tag,
  Tooltip,
} from '@blueprintjs/core';
import { gql, useMutation } from '@apollo/client';
import _ from 'lodash';

import Checkbox from 'components/FormFields/Checkbox';
import TextField from 'components/FormFields/TextField';
import TagField from 'components/FormFields/TagField';
import Table from 'components/Table';
import useCheckPermission from 'hooks/use-check-permission';
import toaster from 'helpers/toaster';
import SizeGradeDialog from './SizeGradeDialog';

import styles from './index.module.css';

const ADD_SIZE_GRADED = gql`
  mutation AddSizeGraded($categoryPropertyId: Int!) {
    categoryProperty: categoryPropertyAddSizeGraded(categoryPropertyId: $categoryPropertyId) {
      sizeGrades {
        id
        identifier
        value
      }
    }
  }
`;

const REMOVE_SIZE_GRADED = gql`
mutation RemoveSizeGraded($categoryPropertyId: Int!) {
  categoryPropertyRemoveSizeGraded(categoryPropertyId: $categoryPropertyId) {
    id
  }
}
`;

interface Props {
  fields: any;
  onDelete: (index: number) => void;
}

const columns = [
  'Order',
  'Name',
  'Description',
  'Min',
  'Max',
  'Values',
  'Size-Graded',
  'Visible',
  'Required',
  'Actions',
];

const formatRow = (field: any) => {
  return {
    id: field.categoryPropertyId,
    property: field.property,
    description: field.description,
    min: field.min,
    max: field.max,
    values: field.values,
    sizeGraded: field.sizeGraded,
    sizeGrades: field.sizeGrades,
    visible: field.visible,
    ordinality: field.ordinality,
  };
};

const renderDefaultValueTooltip = (property: any) => {
  const valueTags = property.values.map((v: string, i: number) => (
    <Tag key={`${property.id}-${i}`}>{v}</Tag>
  ));
  return (
    <Tooltip
      position={PopoverPosition.RIGHT}
      content={(
        <>
          <h6 className="bp4-heading">Default Property Values</h6>
          <ul className={styles.defaultPropertyList}>
            <li>Description: {property.description || <em>Not set</em>}</li>
            <li>Min: {property.min || <em>Not set</em>}</li>
            <li>Max: {property.max || <em>Not set</em>}</li>
            <li className={styles.defaultValues}>Values: {valueTags}</li>
            <li>Unit: {property.unit || <em>Not set</em>}</li>
          </ul>
        </>
      )}
    >
      <Icon icon="info-sign" className={styles.icon} />
    </Tooltip>
  );
};

export default (props: Props) => {
  const { control, setValue, watch } = useFormContext();

  const [canAddSizeGrades] = useCheckPermission('create_size_grades');
  const [canEditSizeGrades] = useCheckPermission('update_size_grades');
  const [canDeleteCategoryProperties] = useCheckPermission('delete_category_properties');

  const [addSizeGraded] = useMutation(ADD_SIZE_GRADED, {
    onError: ({ message }) => toaster.show({
      intent: Intent.DANGER,
      message: `Error adding size-graded: ${message}`,
    }),
  });
  const [removeSizeGraded] = useMutation(REMOVE_SIZE_GRADED, {
    ignoreResults: true,
    onError: ({ message }) => toaster.show({
      intent: Intent.DANGER,
      message: `Error removing size-graded: ${message}`,
    }),
  });

  const [sizeGradeDisabledPropertyPath, setSizeGradeDisabledPropertyPath] = useState<string>();
  const [isRemoveSizeGradeDialogOpen, setRemoveSizeGradeDialogOpen] = useState(false);

  // Keeps track of the size graded property for enabling/disabling other size
  // grade inputs/actions
  const [sizeGradedProperty, setSizeGradedProperty] = useState<any>();

  // Uses a simple Boolean array to track the index of properties state of min/max
  // enable/disable and watches for changes to recalculate it
  const [minMaxDisabled, setMinMaxDisabled] = useState<boolean[]>([]);
  useEffect(() => {
    const subscription = watch(async (data, { name }) => {
      const [field, index, property] = name?.split('.') ?? [];
      if (field !== 'properties') return;

      if (property === 'sizeGraded') {
        const disabled = _.get(data, name as string) === false;
        if (disabled) {
          setSizeGradeDisabledPropertyPath(name);
          setRemoveSizeGradeDialogOpen(true);
        } else {
          const { categoryPropertyId } = data[field][index];
          const { data: { categoryProperty } } = await addSizeGraded({ variables: { categoryPropertyId } });
          setValue(`properties.${index}.sizeGrades`, categoryProperty.sizeGrades);
        }
      }

      // Set to any (potential) new size graded property, or undefined if none
      const newSizeGradedProperty = data.properties.find((f: any) => f.sizeGraded);
      setSizeGradedProperty(newSizeGradedProperty);

      const disableMinMax = data.properties.map((p: any, i: number) => {
        const result = p.values.length > 0;

        // Clear out `min` and `max` if `values` is set
        if (result && data.properties[i].min !== '') setValue(`properties.${i}.min`, '');
        if (result && data.properties[i].max !== '') setValue(`properties.${i}.max`, '');

        return result;
      });

      setMinMaxDisabled(disableMinMax);
    });
    return () => subscription.unsubscribe();
  }, [addSizeGraded, removeSizeGraded, setValue, sizeGradedProperty, watch]);

  const handleConfirmRemoveSizeGrade = async () => {
    const index = sizeGradeDisabledPropertyPath?.split('.')[1];
    if (index) {
      const { categoryPropertyId } = _.get(props.fields, index);
      await removeSizeGraded({ variables: { categoryPropertyId } });
    }

    setRemoveSizeGradeDialogOpen(false);
    setSizeGradeDisabledPropertyPath(undefined);
  };

  const handleCancelRemoveSizeGrade = () => {
    if (sizeGradeDisabledPropertyPath) {
      setValue(sizeGradeDisabledPropertyPath, true);
    }
    setRemoveSizeGradeDialogOpen(false);
    setSizeGradeDisabledPropertyPath(undefined);
  };

  const rowRenderer = (row: any, index: number) => {
    return (
      <tr key={`category-property-${row.id}`}>
        <td className={styles.ordinality}>
          <TextField
            control={control}
            config={{
              name: `properties.${index}.ordinality`,
            }}
            formGroupProps={{
              className: styles.propertyEditTableFormGroup,
            }}
          />
        </td>
        <td className={styles.name}>
          {row.property.name}
          {renderDefaultValueTooltip(row.property)}
        </td>
        <td className={styles.description}>
          <TextField
            control={control}
            config={{ name: `properties.${index}.description` }}
            formGroupProps={{
              className: styles.propertyEditTableFormGroup,
            }}
          />
        </td>
        <td className={styles.min}>
          <TextField
            control={control}
            config={{
              disabled: minMaxDisabled[index],
              name: `properties.${index}.min`,
            }}
            formGroupProps={{
              className: styles.propertyEditTableFormGroup,
            }}
          />
        </td>
        <td className={styles.max}>
          <TextField
            control={control}
            config={{
              disabled: minMaxDisabled[index],
              name: `properties.${index}.max`,
            }}
            formGroupProps={{
              className: styles.propertyEditTableFormGroup,
            }}
          />
        </td>
        <td>
          <TagField
            control={control}
            config={{ name: `properties.${index}.values` }}
            formGroupProps={{
              className: styles.propertyEditTableFormGroup,
            }}
          />
        </td>
        <td className={styles.max}>
          <div className={styles.sizeGrade}>
            <Checkbox
              control={control}
              config={{
                name: `properties.${index}.sizeGraded`,
                disabled: (sizeGradedProperty && sizeGradedProperty?.categoryPropertyId !== row.id)
                  || (!canAddSizeGrades && !canEditSizeGrades),
              }}
            />
            {sizeGradedProperty?.categoryPropertyId === row.id ? (
              <SizeGradeDialog categoryProperty={row} />
            ) : null}
          </div>
        </td>
        <td className={styles.max}>
          <Checkbox
            control={control}
            config={{
              name: `properties.${index}.visible`,
            }}
          />
        </td>
        <td>
          <Checkbox
            control={control}
            config={{
              name: `properties.${index}.required`,
            }}
          />
        </td>
        <td>
          {canDeleteCategoryProperties && (
            <Button
              icon="trash"
              intent={Intent.DANGER}
              onClick={() => props.onDelete(index)}
            />
          )}
        </td>
      </tr>
    );
  };

  return (
    <>
      <Table
        classNames={[styles.propertyEditTable]}
        columns={columns}
        rows={props.fields.map(formatRow)}
        rowRenderer={rowRenderer}
      />
      <Dialog
        isOpen={isRemoveSizeGradeDialogOpen}
        onClose={handleCancelRemoveSizeGrade}
        usePortal={false}
      >
        <div className={Classes.DIALOG_HEADER}>
          Remove Property Size Grading?
        </div>
        <div className={Classes.DIALOG_BODY}>
          <p>
            Removing this property's size-graded status will remove all {/* eslint-disable-line */}
            associated size grade identifiers. This will be done immediately and cannot be undone.
            <br />
            <br />
            To continue with the removal, press Remove.
          </p>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              intent={Intent.DANGER}
              onClick={handleConfirmRemoveSizeGrade}
              text="Remove"
            />
            <Button
              intent={Intent.PRIMARY}
              onClick={handleCancelRemoveSizeGrade}
              text="Cancel"
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};
