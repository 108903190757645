import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  from,
} from '@apollo/client';

import {
  apiBasePath,
  env,
} from '../config';

const httpLink = createHttpLink({
  uri: `${apiBasePath}/graphql`,
});

const client = new ApolloClient({
  link: from([
    httpLink,
  ]),
  cache: new InMemoryCache(),
  connectToDevTools: env !== 'production',
});

export default client;
