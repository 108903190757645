import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Colors, Icon } from '@blueprintjs/core';

import { selectDarkMode, uiSlice } from 'reducers/ui';

export default () => {
  const darkMode = useSelector(selectDarkMode);
  const dispatch = useDispatch();

  return (
    <Button
      minimal
      onClick={() => dispatch(uiSlice.actions.toggleDarkMode())}
      style={{ color: Colors.GRAY5 }}
      icon={<Icon icon="lightbulb" color={darkMode ? Colors.GRAY5 : Colors.GOLD5} />}
      text="Appearance"
    />
  );
};
