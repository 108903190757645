import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Intent, NonIdealState } from '@blueprintjs/core';
import classNames from 'classnames';

import { LinkWithSearch } from 'components/LinkWithSearch';
import Spinner from 'components/Spinner';
import useCategoryDataByLocation from 'hooks/use-category-data-by-location';
import useProjectByParam from 'hooks/use-project-by-param';
import { selectDarkMode } from 'reducers/ui';
import { sortByIdentifier } from 'helpers/general';

import styles from './index.module.css';

export default () => {
  const navigate = useNavigate();
  const darkMode: boolean = useSelector(selectDarkMode);
  const { loading, error, categoryByLocation } = useCategoryDataByLocation();
  const {
    loading: projectLoading,
    error: projectError,
    data: projectData,
  } = useProjectByParam();

  const renderSubcategory = (subcategory: any) => {
    return (
      <li key={subcategory.id}>
        <h2 className={styles.subcategoryHeader}>
          <LinkWithSearch
            className={classNames(styles.categoryLink, {
              [styles.categoryLinkLight]: !darkMode,
              [styles.categoryLinkDark]: darkMode,
            })}
            to={`/categories/${subcategory.path}`}
          >
            {subcategory.name}
          </LinkWithSearch>
        </h2>
        <hr className={styles.categoryRuler} />
        <ol className={styles.subcategoryList}>
          {subcategory.subcategories.map((c: any) => (
            <li
              key={c.id}
              className={styles.subcategoryListItem}
            >
              <LinkWithSearch
                className={classNames(styles.categoryLink, {
                  [styles.categoryLinkLight]: !darkMode,
                  [styles.categoryLinkDark]: darkMode,
                })}
                to={`/categories/${c.path}`}
              >
                {c.name}
              </LinkWithSearch>
            </li>
          ))}
        </ol>
      </li>
    );
  };

  if (loading || projectLoading) return <Spinner />;
  if (error || projectError) throw error;

  return (
    <div className={styles.container}>
      <h1 className={classNames('bp4-heading', styles.categoryHeader)}>
        {categoryByLocation.name} {projectData ? `for ${projectData.project.name}` : ''}
      </h1>
      {categoryByLocation.description && (<p>{categoryByLocation.description}</p>)}
      {categoryByLocation.categories?.length > 0 && (
        <ol className={styles.parentCategoryList}>
          {sortByIdentifier(categoryByLocation.categories).map(renderSubcategory)}
        </ol>
      )}
      {projectData && categoryByLocation?.categories.length === 0 && (
        <NonIdealState
          icon="search"
          title="No Project Parts"
          description="No parts have been assigned to this project."
          action={(
            <Button
              className={styles.button}
              icon="clean"
              intent={Intent.PRIMARY}
              onClick={() => navigate(`/parts/create?projectId=${projectData.project.id}`)}
            >
              Add Part
            </Button>
          )}
        />
      )}
    </div>
  );
};
