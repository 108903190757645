import { gql, useQuery } from '@apollo/client';
import { useLocation, useSearchParams } from 'react-router-dom';

import { CATEGORY_ROOT_PATH_REGEXP } from '../constants';

const GET_ROOT_CATEGORIES = gql`
  query GetRootCategories {
    categories: categoriesByParentCategory(parentCategoryId: null) {
      id
      identifier
      name
      description
      path
      subcategoryCount
      subcategories {
        id
        name
        path
      }
    }
  }
`;

const GET_ROOT_CATEGORIES_BY_PROJECT = gql`
  query GetRootCategoriesByProject($projectId: Int!) {
    categories: projectCategories(projectId: $projectId) {
      id
      identifier
      name
      description
      path
      subcategoryCount: subcategoriesByProjectCount(projectId: $projectId)
      subcategories: subcategoriesByProject(projectId: $projectId) {
        id
        name
        path
      }
    }
  }
`;

const GET_CATEGORY_BY_PATH = gql`
  query CategoryByPath($path: String!) {
    category: categoryByPath(path: $path) {
      id
      identifier
      name
      description
      subcategoryCount
      subcategories {
        id
        identifier
        name
        path
        subcategoryCount
        subcategories {
          id
          name
          path
        }
      }
    }
  }
`;

const GET_PROJECT_CATEGORY_BY_PATH = gql`
  query ProjectCategoryByPath($projectId: Int!, $path: String!) {
    category: projectCategoryByPath(projectId: $projectId, path: $path) {
      id
      identifier
      name
      description
      subcategoryCount: subcategoriesByProjectCount(projectId: $projectId)
      subcategories: subcategoriesByProject(projectId: $projectId) {
        id
        name
        path
        subcategoryCount: subcategoriesByProjectCount(projectId: $projectId)
        subcategories: subcategoriesByProject(projectId: $projectId) {
          id
          name
          path
        }
      }
    }
  }
`;

const GET_CATEGORY_SUBCATEGORIES_BY_ID = gql`
  query CategorySubcategoriesById($categoryId: Int!) {
    category: categoryById(categoryId: $categoryId) {
      id
      identifier
      subcategories {
        id
        identifier
        name
        path
        subcategoryCount
      }
    }
  }
`;

const GET_SUBCATEGORIES_BY_PATH = gql`
  query SubcategoriesByPath($path: String!) {
    category: categoryByPath(path: $path) {
      id
      identifier
      name
      subcategoryCount
      parentCategory {
        id
        subcategories {
          id
          identifier
          name
          path
          subcategoryCount
        }
      }
    }
  }
`;

const GET_PROJECT_SUBCATEGORIES_BY_PATH = gql`
  query ProjectSubcategoriesByPath($projectId: Int!, $path: String!) {
    category: projectCategoryByPath(projectId: $projectId, path: $path) {
      id
      identifier
      subcategoryCount: subcategoriesByProjectCount(projectId: $projectId)
      parentCategory {
        id
        subcategories: subcategoriesByProject(projectId: $projectId) {
          id
          identifier
          name
          path
          subcategoryCount: subcategoriesByProjectCount(projectId: $projectId)
        }
      }
    }
  }
`;

/*
  Use cases:
    - View root categories (i.e. no parent category).
    - View subcategories for a single category who has subcategories.
    - View sibling categories for a category that has no subcategories.
    - View subcategories of a category who is having a new subcategory created.
*/
export default (): { loading: boolean, error: any, categoryByLocation: any } => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const rawParentCategoryId = searchParams.get('parentCategoryId');
  const parentCategoryId = rawParentCategoryId ? parseInt(rawParentCategoryId, 10) : null;
  const rawProjectId = searchParams.get('projectId');
  const projectId = rawProjectId ? parseInt(rawProjectId, 10) : null;
  const isProjectView = Boolean(projectId);

  const onRootCategoryPage = /^\/categories(\/edit)?(\/)?$/.test(pathname); // `/categories` or `/categories[/edit]`
  const onCategoryPage = /^\/categories\/\S+$/.test(pathname); // `/categories/path/to/subcategory`
  const onCategoryCreatePage = /^\/categories\/create$/.test(pathname); // `/categories/create`
  const onCategoryEditPage = /^\/categories\/edit\/\S+$/.test(pathname); // `/categories/edit/path/to/subcategory`

  const path = pathname.replace(CATEGORY_ROOT_PATH_REGEXP, '');

  // Homepage: Return root categories
  const {
    loading: rootCategoriesLoading,
    error: rootCategoriesError,
    data: rootCategoriesData,
  } = useQuery(GET_ROOT_CATEGORIES, {
    skip: isProjectView || (!onRootCategoryPage && parentCategoryId !== null),
    fetchPolicy: 'cache-and-network',
  });
  const {
    loading: rootCategoriesByProjectLoading,
    error: rootCategoriesByProjectError,
    data: rootCategoriesByProjectData,
  } = useQuery(GET_ROOT_CATEGORIES_BY_PROJECT, {
    variables: { projectId },
    skip: !isProjectView,
    fetchPolicy: 'cache-and-network',
  });

  // Subcategory page: Return subcategories if the exist, otherwise return sibling categories
  const {
    loading: categoryByPathLoading,
    error: categoryByPathError,
    data: categoryByPathData,
  } = useQuery(GET_CATEGORY_BY_PATH, {
    variables: { path },
    skip: isProjectView || (!onCategoryPage && !onCategoryEditPage),
    fetchPolicy: 'cache-and-network',
  });
  const {
    loading: projectCategoryByPathLoading,
    error: projectCategoryByPathError,
    data: projectCategoryByPathData,
  } = useQuery(GET_PROJECT_CATEGORY_BY_PATH, {
    variables: { projectId, path },
    skip: !isProjectView || !path,
    fetchPolicy: 'cache-and-network',
  });
  const {
    loading: subcategoriesByPathLoading,
    error: subcategoriesByPathError,
    data: subcategoriesByPathData,
  } = useQuery(GET_SUBCATEGORIES_BY_PATH, {
    variables: { path },
    // Skip fetching sibiling categories for the category being viewed because the user can drill
    // down to more categories. Otherwise, show the sibiling categories for the selected category
    // because the category nav list would be empty.
    skip: isProjectView || (!onCategoryPage && categoryByPathData?.subcategoryCount > 0),
    fetchPolicy: 'cache-and-network',
  });
  const {
    loading: projectSubcategoriesByPathLoading,
    error: projectSubcategoriesByPathError,
    data: projectSubcategoriesByPathData,
  } = useQuery(GET_PROJECT_SUBCATEGORIES_BY_PATH, {
    variables: { projectId, path },
    // Skip fetching sibiling categories for the category being viewed because the user can drill
    // down to more categories. Otherwise, show the sibiling categories for the selected category
    // because the category nav list would be empty.
    skip: !isProjectView || !path,
    fetchPolicy: 'cache-and-network',
  });

  // Category create page: Return subcategories for the category who is having a new subcategory created.
  const {
    loading: categorySubcategoriesByIdLoading,
    error: categorySubcategoriesByIdError,
    data: categorySubcategoriesByIdData,
  } = useQuery(GET_CATEGORY_SUBCATEGORIES_BY_ID, {
    variables: { categoryId: parentCategoryId },
    skip: (!onCategoryCreatePage || parentCategoryId === null),
    fetchPolicy: 'cache-and-network',
  });

  if (onRootCategoryPage) {
    return {
      loading: rootCategoriesLoading || rootCategoriesByProjectLoading,
      error: rootCategoriesError || rootCategoriesByProjectError,
      categoryByLocation: { // Return a stubbed parent of root categories
        id: null, // Used as the `parentCategoryId` when creating a new category
        name: 'All categories',
        categories: (rootCategoriesData ?? rootCategoriesByProjectData)?.categories,
        subcategoryCount: 1, // So that `<CategoryView />` shows the list instead of detail
      },
    };
  }

  if (onCategoryCreatePage) {
    if (parentCategoryId === null) {
      return {
        loading: rootCategoriesLoading,
        error: rootCategoriesError,
        categoryByLocation: { // Return a stubbed parent of root categories
          id: null, // Used as the `parentCategoryId` when creating a new category
          name: 'All categories',
          categories: rootCategoriesData?.categories,
          subcategoryCount: 1, // So that `<CategoryView />` shows the list instead of detail
        },
      };
    }

    return {
      loading: categorySubcategoriesByIdLoading,
      error: categorySubcategoriesByIdError,
      categoryByLocation: {
        id: categorySubcategoriesByIdData?.category?.id,
        name: categorySubcategoriesByIdData?.category?.name,
        categories: categorySubcategoriesByIdData?.category?.subcategories,
      },
    };
  }

  if (onCategoryPage || onCategoryEditPage) {
    // Return a stubbed response until the first query has completed since it'll determine what happens next.
    if (categoryByPathLoading || projectCategoryByPathLoading) {
      return {
        loading: true,
        error: null,
        categoryByLocation: {
          id: null,
          name: null,
          description: null,
          categories: [],
        },
      };
    }

    const parentData = categoryByPathData ?? projectCategoryByPathData;
    if (parentData?.category?.subcategoryCount > 0) {
      return {
        loading: categoryByPathLoading || projectCategoryByPathLoading,
        error: categoryByPathError || projectCategoryByPathError,
        categoryByLocation: {
          id: parentData?.category?.id,
          name: parentData?.category?.name,
          description: parentData?.category?.description,
          categories: parentData?.category?.subcategories,
          subcategoryCount: parentData?.category?.subcategoryCount,
        },
      };
    }

    const subcategoryData = subcategoriesByPathData ?? projectSubcategoriesByPathData;
    return {
      loading: subcategoriesByPathLoading || projectSubcategoriesByPathLoading,
      error: subcategoriesByPathError || projectSubcategoriesByPathError,
      categoryByLocation: {
        id: subcategoryData?.category?.id,
        name: subcategoryData?.category?.name,
        // Empty array fallback is for when the selected category is a root category without any subcategories.
        categories: subcategoryData?.category?.parentCategory?.subcategories ?? [],
        subcategoryCount: parentData?.category?.subcategoryCount,
      },
    };
  }

  // Fallback that should never be reached
  return {
    loading: false,
    error: null,
    categoryByLocation: {
      id: -1,
      name: 'Fallback category name',
      categories: [],
      subcategories: [],
    },
  };
};
