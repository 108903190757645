import React from 'react';
import {
  useLocation,
} from 'react-router-dom';
import {
  gql,
  useQuery,
} from '@apollo/client';
import { Button, FormGroup, MenuItem } from '@blueprintjs/core';
import { ItemPredicate, ItemRenderer, Select } from '@blueprintjs/select';
import { useFormContext } from 'react-hook-form';
import _ from 'lodash';

import Spinner from 'components/Spinner';
import ManualError from 'components/ManualError';
// import toaster from 'helpers/toaster';

import styles from './index.module.css';

const GET_CATEGORY_BY_PATH_AND_ALL_PROPERTIES = gql`
  query GetCategoryByPathAndAllProperties($path: String!) {
    categoryByPath(path: $path) {
      id
      identifier
      name
      categoryProperties {
        property {
          id
          name
        }
      }
    }
    properties {
      id
      name
      description
      min
      max
      values
    }
  }
`;

interface Props {
  onAddPropertySelect: (property: any) => void;
}

export default (props: Props) => {
  const { pathname } = useLocation();
  const { getValues } = useFormContext();

  const {
    loading: getCategoryAndPropertyDataLoading,
    error: getCategoryAndPropertyDataError,
    data: getCategoryAndPropertyDataData,
  } = useQuery(GET_CATEGORY_BY_PATH_AND_ALL_PROPERTIES, { variables: { path: pathname } });

  if (getCategoryAndPropertyDataLoading) {
    return <Spinner />;
  }
  if (getCategoryAndPropertyDataError) {
    return (
      <ManualError
        description={getCategoryAndPropertyDataError?.message}
      />
    );
  }

  const propertyPredicate: ItemPredicate<any> = (query, property) => {
    return property.name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
  };
  const propertyRenderer: ItemRenderer<any> = (property, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) return null;

    return (
      <MenuItem
        key={property.id}
        onClick={handleClick}
        text={property.name}
      />
    );
  };

  const filteredProperties = _.filter(getCategoryAndPropertyDataData.properties, property => {
    const categoryPropertyIds = _.map(getValues('properties'), 'property.id');
    return !_.includes(categoryPropertyIds, property.id);
  });

  return (
    <FormGroup
      className={styles.propertyDropdownContainer}
      label="Add Property"
    >
      <Select
        filterable
        itemPredicate={propertyPredicate}
        itemRenderer={propertyRenderer}
        items={filteredProperties}
        onItemSelect={props.onAddPropertySelect}
      >
        <Button rightIcon="double-caret-vertical">Select property</Button>
      </Select>
    </FormGroup>
  );
};
