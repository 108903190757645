import React from 'react';

import Select from 'components/FormFields/Select';
import TagField from 'components/FormFields/TagField';
import TextField from 'components/FormFields/TextField';
import TextArea from 'components/FormFields/TextArea';

interface SelectFormData {
  label: string;
  value: string;
}

interface Props {
  control: any;
  fieldsConfig: any;
  types: SelectFormData[];
  units: SelectFormData[];
}

export default (props: Props) => {
  return (
    <>
      <TextField
        control={props.control}
        config={props.fieldsConfig.name}
      />

      <TextArea
        control={props.control}
        config={props.fieldsConfig.description}
      />

      <Select
        control={props.control}
        config={props.fieldsConfig.type}
        options={props.types}
      />

      <TextField
        control={props.control}
        config={props.fieldsConfig.min}
      />

      <TextField
        control={props.control}
        config={props.fieldsConfig.max}
      />

      <TagField
        control={props.control}
        config={props.fieldsConfig.values}
      />

      <Select
        control={props.control}
        config={props.fieldsConfig.unit}
        options={props.units}
      />
    </>
  );
};
