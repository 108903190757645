import React from 'react';
import { Navigate, Route, createRoutesFromElements } from 'react-router-dom';

import CategoryNav from 'components/CategoryNav';
import Layout from 'components/Layout';

import Audit from './audit';

import Projects from './projects';
import ProjectDetail from './project-detail';
import ProjectCreate from './project-create';
import ProjectEdit from './project-edit';

import Properties from './properties';
import PropertyDetail from './property-detail';
import PropertyCreate from './property-create';
import PropertyEdit from './property-edit';

import CategoryView from './category-view';
import CategoryCreate from './category-create';
import CategoryEdit from './category-edit';

import PartView from './part-view';
import PartCreate from './part-create';
import PartEdit from './part-edit';

import Search from './search';

const routes = createRoutesFromElements(
  <>
    <Route path="/" element={<Layout />}>
      <Route index element={<Navigate to="/categories" />} />

      <Route path="audit">
        <Route index element={<Audit />} />
      </Route>

      <Route path="categories">
        <Route index element={<CategoryView />} />
        <Route path="create" element={<CategoryCreate />} />
        <Route path="edit/*" element={<CategoryEdit />} />
        <Route path="*" element={<CategoryView />} />
      </Route>

      <Route path="projects">
        <Route index element={<Projects />} />
        <Route path="create" element={<ProjectCreate />} />
        <Route path="edit/:projectId" element={<ProjectEdit />} />
        <Route path=":projectId" element={<ProjectDetail />} />
      </Route>

      <Route path="parts">
        <Route index element={<CategoryNav />} />
        <Route path="create" element={<PartCreate />} />
        <Route path="edit/*" element={<PartEdit />} />
        <Route path="*" element={<PartView />} />
      </Route>

      <Route path="properties">
        <Route index element={<Properties />} />
        <Route path="create" element={<PropertyCreate />} />
        <Route path="edit/:propertyId" element={<PropertyEdit />} />
        <Route path=":propertyId" element={<PropertyDetail />} />
      </Route>

      <Route path="search">
        <Route index element={<Search />} />
      </Route>
    </Route>


    <Route path="*" element={<Navigate to="/categories" />} />
  </>
);

export default routes;
