import React from 'react';
import { Provider } from 'react-redux';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';

import { basename } from 'config';
import { store } from 'store';
import useCheckPermission from 'hooks/use-check-permission';
import client from './graphql';
import routes from './routes';

const router = createBrowserRouter(routes, { basename });

export default () => {
  const [canAccessDev] = useCheckPermission('access_dev');
  const [canAccessStaging] = useCheckPermission('access_staging');

  const isDev = /^dev/.test(window.location.hostname);
  const isStaging = /^staging/.test(window.location.hostname);

  if (isDev && canAccessDev === false) throw new Error('Access denied');
  if (isStaging && canAccessStaging === false) throw new Error('Access denied');

  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </ApolloProvider>
  );
};
