import search, { SearchState } from './search';
import ui, { UIState } from './ui';

export interface State {
  search: SearchState;
  ui: UIState;
}

export default {
  search,
  ui,
};
