import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Colors, Icon } from '@blueprintjs/core';
import classNames from 'classnames';
import _ from 'lodash';

import { selectDarkMode } from 'reducers/ui';

import styles from './index.module.css';

interface Props {
  columns: string[];
  rows: string[][] | { link: string, values: string[] }[];
  rowRenderer: (values: string[], rowIndex: number) => JSX.Element;
  classNames?: string[];
  sortable?: boolean;
  sticky?: { top: number };
}

const Table = (props: Props) => {
  const [colIndexToSortBy, setColIndexToSortBy] = useState<number | null>(null);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const darkMode = useSelector(selectDarkMode);

  const handleSort = (colIndex: number) => {
    if (!props.sortable) return;

    setColIndexToSortBy(colIndex);

    if (colIndex !== colIndexToSortBy) setSortOrder('desc'); // Click #1: Column sort desc
    else if (sortOrder === 'desc') setSortOrder('asc'); // Click #2: Column sort asc
    else if (sortOrder === 'asc') setColIndexToSortBy(null); // Click #3: Column sort default
  };

  const renderSortIcon = (colIndex: number) => {
    if (colIndexToSortBy !== colIndex) return null;
    if (sortOrder === 'desc') return <Icon icon="arrow-down" className={styles.icon} />;
    return <Icon icon="arrow-up" className={styles.icon} />;
  };

  const renderTableHead = (name: string, index: number) => {
    return (
      <th
        key={index}
        onClick={() => handleSort(index)}
        className={classNames({
          [styles.sortable]: props.sortable,
          [styles.stickyHeader]: props.sticky,
        })}
        style={{
          backgroundColor: darkMode ? Colors.DARK_GRAY2 : Colors.LIGHT_GRAY2,
          top: props.sticky?.top || 0,
        }}
      >
        {name}
        {renderSortIcon(index)}
      </th>
    );
  };

  return (
    <div className={styles.wrapper}>
      <table className={classNames(
        ['bp4-html-table', ...props.classNames || []],
        { [styles.stickyTable]: props.sticky }
      )}
      >
        <thead>
          <tr>
            {props.columns.map(renderTableHead)}
          </tr>
        </thead>
        <tbody>
          {_.chain(props.rows)
            .orderBy((row: any) => {
              if (colIndexToSortBy === null) return null;
              // If row is a 1d array then use it, otherwise reach into the row object and use the `values` property.
              const values = (_.isArray(row)) ? row : row.values;
              return values[colIndexToSortBy];
            }, sortOrder)
            .map(props.rowRenderer)
            .value()
          }
        </tbody>
      </table>
    </div>
  );
};

Table.defaultProps = {
  classNames: [],
  sortable: false,
  sticky: false,
};

export default Table;
