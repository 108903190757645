import { gql, useQuery } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';

const GET_PROJECT_BY_ID = gql`
  query GetProjectById($projectId: Int!) {
    project: projectById(projectId: $projectId) {
      id
      name
      identifier
    }
  }
`;

export default (): { loading: boolean, error: any, data: any } => {
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get('projectId');
  const { loading, error, data } = useQuery(GET_PROJECT_BY_ID, {
    variables: { projectId: Number(projectId) },
    skip: !projectId,
  });

  return { loading, error, data };
};
