import GitInfo from 'react-git-info/macro';
import * as packageInfo from '../package.json';

export const commitHash = GitInfo().commit.shortHash;
export const packageJson = packageInfo;

export const env = process.env.NODE_ENV;
export const basename = (env === 'development') ? '/part-numbering' : '';
export const sentryDSN = process.env.REACT_APP_SENTRY_DSN;
export const apiBasePath = process.env.REACT_APP_API_BASE_PATH || '/api/v1';
export const eventsApiBasePath = process.env.REACT_APP_EVENTS_API_BASE_PATH || '/api/trd';
