import {
  basename,
  env,
} from 'config';

export const PropertyTypeMap: Record<string, string> = {
  NUMBER: 'Number',
  STRING: 'Text',
};

export function determinePlurality(count: number, word: string, pluralOverride?: string) {
  if (count === 1) return word;
  if (pluralOverride) return pluralOverride;
  return `${word}s`;
}

export function sortByIdentifier(records: []): any[] {
  const recordsToSort = [...records];

  return recordsToSort.sort((a: any, b: any) => {
    if (a.identifier > b.identifier) return 1;
    if (a.identifier < b.identifier) return -1;
    return 0;
  });
}

export function getFullyQualifiedUrl(path?: string) {
  if (env === 'development') return `http://localhost${basename}${path}`;
  return `${window.location.origin}${path}`;
}

export const parseFractionStr = (str: string): number => {
  const match = str.match(/^(?<wholeNumber>\d*(?!.\/))?\s?((?<numerator>\d+)\/(?<denominator>\d+))?$/);

  if (match === null || match.groups === undefined) return -1;

  // If parsing fails, use 0 since the value is not a number (i.e. doesn't exist)
  const wholeNumber = parseInt(match.groups.wholeNumber, 10) || 0;
  const fraction = parseInt(match.groups.numerator, 10) / parseInt(match.groups.denominator, 10) || 0;

  return wholeNumber + fraction;
};
