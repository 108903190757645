import React from 'react';
import { Button, Intent } from '@blueprintjs/core';
import classNames from 'classnames';

import Select from 'components/FormFields/Select';

import styles from './index.module.css';

const generateConfig = (property: any, index: number) => ({
  name: `filters.${index}`,
  label: property.name,
  multiple: true,
});

const formatOptions = (property: any) => {
  return property.defaultValues
    .sort()
    .map((value: string) => ({ label: value, value }));
};

export default (props: any) => {
  const renderProperty = (property: any, index: number) => {
    return (
      <div key={property.id} className={styles.propertyContainer}>
        <Select
          control={props.control}
          config={generateConfig(property, index)}
          options={formatOptions(property)}
          selectClassName={styles.valueSelect}
        />
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.listContainer}>
        <Button
          className={classNames('bp4-button', styles.clearFiltersButton)}
          fill
          intent={Intent.DANGER}
          onClick={props.clearFilters}
        >
          Clear All
        </Button>
        <div>
          {props.properties.map(renderProperty)}
        </div>
      </div>
    </div>
  );
};
