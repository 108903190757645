import React, { useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Button, Classes, Dialog, Intent, MenuItem } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import classNames from 'classnames';
import _ from 'lodash';

import FormButtons from 'components/FormButtons';
import TextField from 'components/FormFields/TextField';
import Spinner from 'components/Spinner';
import toaster from 'helpers/toaster';

import styles from './index.module.css';

const GET_SIZE_GRADES = gql`
  query GetSizeGrades($categoryPropertyId: Int!) {
    sizeGrades: sizeGradesByCategoryProperty(categoryPropertyId: $categoryPropertyId) {
      id
      identifier
      value
    }
  }
`;

const BATCH_UPDATE_SIZE_GRADES = gql`
  mutation SizeGradeBatchUpdate($sizeGradeInputs: [SizeGradeInput]!) {
    sizeGradeBatchUpdate(sizeGradeInputs: $sizeGradeInputs) {
      id
      value
      identifier
    }
  }
`;

const GET_CATEGORY_PROPERTIES_BY_PROPERTY_NAME = gql`
  query CategoryPropertiesByPropertyName($propertyName: String!) {
    categoryProperties: categoryPropertiesByPropertyName(propertyName: $propertyName) {
      id
      category {
        identifier
        name
      }
      sizeGraded
      sizeGrades {
        value
        identifier
      }
    }
  }
`;

interface SizeGrade {
  id: number;
  value: string;
  identifier: string;
}

interface Props {
  categoryProperty: any;
}

export default (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [categoryProperties, setCategoryProperties] = useState([]);
  const {
    control,
    formState: { dirtyFields },
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      sizeGrades: [] as SizeGrade[],
    },
  });
  const { fields, append } = useFieldArray({ control, name: 'sizeGrades' });

  useQuery(GET_SIZE_GRADES, {
    variables: { categoryPropertyId: props.categoryProperty.id },
    onCompleted: data => {
      append(data.sizeGrades);
    },
  });

  useQuery(GET_CATEGORY_PROPERTIES_BY_PROPERTY_NAME, {
    variables: { propertyName: props.categoryProperty.property.name },
    onCompleted: data => {
      setCategoryProperties(data?.categoryProperties.filter((c: any) => {
        return c.sizeGraded && c.id !== props.categoryProperty.id;
      }));
    },
  });

  const [batchUpdateSizeGrades, {
    loading: batchUpdateSizeGradesLoading,
  }] = useMutation(BATCH_UPDATE_SIZE_GRADES, {
    onCompleted: () => {
      setIsOpen(false);
      toaster.show({
        intent: Intent.SUCCESS,
        message: 'Size grades updated successfully',
      });
    },
    onError: ({ message }) => toaster.show({
      intent: Intent.DANGER,
      message: `Error updating size grades: ${message}`,
    }),
  });

  const onSelectCategoryProperty = (item: any) => {
    for (let i = 0; i < fields.length; i++) {
      const thisSizeGrade = fields[i];
      const thatSizeGrade = item.sizeGrades?.find((s: any) => s.value === thisSizeGrade.value);
      if (thatSizeGrade) {
        setValue(`sizeGrades.${i}.identifier`, thatSizeGrade.identifier);
      }
    }
  };

  const onSubmit = async (formData: { sizeGrades: SizeGrade[] }) => {
    const dirtyIndices = dirtyFields.sizeGrades?.reduce((acc, __, index) => {
      acc.push(index);
      return acc;
    }, [] as number[]) ?? [];
    const locallyUpdatedSizeGrades = _.map(_.at(formData.sizeGrades, dirtyIndices), sizeGrade => ({
      categoryPropertyId: props.categoryProperty.id,
      id: sizeGrade.id,
      identifier: sizeGrade.identifier,
    }));
    await batchUpdateSizeGrades({
      variables: {
        sizeGradeInputs: locallyUpdatedSizeGrades,
      },
    });
  };

  return (
    <>
      <Button
        icon="edit"
        minimal
        onClick={() => setIsOpen(!isOpen)}
      />
      <Dialog
        className={styles.sizeGradeDialog}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title={`Edit ${props.categoryProperty.property.name} Size Grades`}
        usePortal={false}
      >
        <div className={classNames(Classes.DIALOG_BODY, styles.sizeGradeDialogBody)}>
          {batchUpdateSizeGradesLoading && <Spinner />}
          {_.size(categoryProperties) > 0 && (
            <div className={styles.sizeGradeCopySelectRow}>
              <span>Copy from {props.categoryProperty.property.name} on</span>
              <Select
                items={categoryProperties}
                itemRenderer={(item: any) => {
                  return (
                    <MenuItem
                      key={item.category.identifier}
                      text={`${item.category.identifier} - ${item.category.name}`}
                      onClick={() => onSelectCategoryProperty(item)}
                    />
                  );
                }}
                onItemSelect={onSelectCategoryProperty}
              >
                <Button rightIcon="double-caret-vertical">Select property</Button>
              </Select>
            </div>
          )}
          <div className={styles.sizeGradeLabelRow}>
            <span className={styles.sizeGradeLabel}>Value</span>
            <span className={styles.sizeGradeLabel}>Identifier</span>
          </div>
          {fields.map((sizeGrade, index) => (
            <div
              key={`size-grade-${index}`}
              className={styles.sizeGradeRow}
            >
              <TextField
                control={control}
                config={{
                  disabled: true,
                  name: `sizeGrades.${index}.value`,
                }}
                formGroupProps={{
                  className: styles.sizeGradeValue,
                }}
              />
              <TextField
                control={control}
                config={{
                  name: `sizeGrades.${index}.identifier`,
                  validation: {
                    required: true,
                    pattern: /\d{3}/,
                  },
                  validationMessages: {
                    pattern: 'Must be 3 digits',
                  },
                }}
                formGroupProps={{
                  className: styles.sizeGradeIdentifier,
                }}
              />
            </div>
          ))}
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <FormButtons
              submitHandler={handleSubmit(onSubmit)}
              cancelHandler={() => setIsOpen(false)}
              disabled={batchUpdateSizeGradesLoading}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};
