import React from 'react';

import Table from 'components/Table';

import styles from './index.module.css';

interface Props {
  properties: any[];
}

const columns = ['Field', 'Value'];

const formatRow = (property: any): string[] => {
  const row = [
    property.categoryProperty.property.name,
  ];

  if (property.value === null) row.push(<em>Not set</em>); // Show `Not set` instead of `null`
  else row.push(`${property.value} ${(property.categoryProperty.property.unit !== null) ? property.categoryProperty.property.unit : ''}`);

  return row;
};

const rowRenderer = (cols: string[], values: string[], rowIndex: number) => {
  return (
    <tr key={`row-${rowIndex}`}>
      {values.map((value, colIndex) => (
        <td
          key={`row-${rowIndex}-col-${colIndex}`}
          className={styles[`cell${cols[colIndex]}`]}
        >
          {value}
        </td>
      ))}
    </tr>
  );
};

export default (props: Props) => {
  return (
    <Table
      classNames={[styles.table]}
      columns={columns}
      rows={props.properties.map(formatRow)}
      rowRenderer={(values, rowIndex) => rowRenderer(columns, values, rowIndex)}
    />
  );
};
