import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import classNames from 'classnames';

import { LinkWithSearch } from 'components/LinkWithSearch';
import ManualError from 'components/ManualError';
import Spinner from 'components/Spinner';
import useCategoryDataByLocation from 'hooks/use-category-data-by-location';
import { sortByIdentifier } from 'helpers/general';
import { selectDarkMode, selectProjectView, uiSlice } from 'reducers/ui';
import { Category } from 'types';
import { CATEGORY_ROOT_PATH_REGEXP } from '../../constants';
import Breadcrumb from './Breadcrumb';
import NavMenu from './NavMenu';

import styles from './index.module.css';

const GET_ALL_PROJECTS = gql`
  query GetAllProjects {
    projects {
      id
      identifier
      name
    }
  }
`;

export function CategoryNav() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const darkMode: boolean = useSelector(selectDarkMode);
  const projectView: boolean = useSelector(selectProjectView);
  const {
    loading: categoryByLocationLoading,
    error: categoryByLocationError,
    categoryByLocation,
  } = useCategoryDataByLocation();
  const {
    loading: getAllProjectsLoading,
    error: getAllProjectsError,
    data: getAllProjectsData,
  } = useQuery(GET_ALL_PROJECTS);

  const isRootCategory = pathname === pathname.match(CATEGORY_ROOT_PATH_REGEXP)?.[0];
  const projectId = searchParams.get('projectId');
  // No dependencies (run once!); If there's a project ID in the URL,
  // OR if there's no project ID and in project view and this isn't
  // the root page, toggle project view automatically on load
  useEffect(() => {
    if ((projectId && !projectView) || (!projectId && projectView && !isRootCategory)) {
      dispatch(uiSlice.actions.toggleProjectView());
    }
  });

  const categoryId = categoryByLocation.id;
  const path = pathname.replace(CATEGORY_ROOT_PATH_REGEXP, '');

  const renderCategories = () => {
    const { categories } = categoryByLocation;

    return sortByIdentifier(categories).map((category: Category) => {
      const categoryIsActive = category.path === path;
      return (
        <LinkWithSearch key={category.id} to={`/categories/${category.path}`}>
          <li className={classNames({ [styles.active]: categoryIsActive })} title={category.name}>
            <span className={styles.ident}>{category.identifier}</span>
            <span>{category.name}</span>
          </li>
        </LinkWithSearch>
      );
    });
  };

  const renderProjects = () => {
    return getAllProjectsData.projects.map((project: any) => {
      const projectIsActive = Number(projectId) === project.id;
      return (
        <Link key={project.id} to={`${pathname}?projectId=${project.id}`}>
          <li className={classNames({ [styles.active]: projectIsActive })}>
            <span className={styles.projectIdent}>{project.identifier}</span> {project.name}
          </li>
        </Link>
      );
    });
  };

  const loading = categoryByLocationLoading || getAllProjectsLoading;
  const error = categoryByLocationError ?? getAllProjectsError;

  return (
    <div className={styles.container}>
      <div className={styles.actionBarContainer}>
        {isRootCategory && !projectId
          ? <span>Choose a {projectView ? 'project' : 'category'}</span>
          : <Breadcrumb />
        }
        <NavMenu
          isRootCategory={isRootCategory}
          categoryId={categoryId}
          isLeafCategory={projectView || categoryByLocation.subcategoryCount === 0}
        />
      </div>
      <div className={classNames(styles.listContainer, {
        [styles.listContainerLight]: !darkMode,
        [styles.listContainerDark]: darkMode,
      })}
      >
        {loading && (
          <Spinner />
        )}
        {error && (
          <ManualError description={error.message} />
        )}
        {!loading && !error && (
          <ol className={classNames(styles.list, {
            [styles.listLight]: !darkMode,
            [styles.listDark]: darkMode,
          })}
          >
            {projectView && isRootCategory ? renderProjects() : renderCategories()}
          </ol>
        )}
      </div>
    </div>
  );
}

export default CategoryNav;
