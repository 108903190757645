import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, MenuItem } from '@blueprintjs/core';
import { Select, ItemRenderer } from '@blueprintjs/select';

import useCheckPermission from 'hooks/use-check-permission';

import styles from './index.module.css';

interface SectionNavItem {
  title: string;
  path: string;
  visible: boolean;
}

const renderItem: ItemRenderer<SectionNavItem> = (item, { handleClick }) => {
  if (!item.visible) return null;

  return (
    <MenuItem
      key={item.title}
      onClick={handleClick}
      text={item.title}
    />
  );
};

const determineSectionNavItems = (canViewAudit: boolean) => (
  [
    { title: 'Categories', path: '/categories', visible: true },
    { title: 'Projects', path: '/projects', visible: true },
    { title: 'Properties', path: '/properties', visible: true },
    { title: 'Audit', path: '/audit', visible: canViewAudit },
  ]
);

const determineNavItem = (pathname: string, sectionNavItems: any) => {
  const str = pathname.split('/')[1];

  switch (str) {
    case 'categories': return sectionNavItems[0];
    case 'projects': return sectionNavItems[1];
    case 'properties': return sectionNavItems[2];
    case 'audit': return sectionNavItems[3];
    default: return sectionNavItems[0];
  }
};

const SectionNavSelect = Select.ofType<SectionNavItem>();

export default () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [canViewAudit] = useCheckPermission('get_audit');

  const sectionNavItems = determineSectionNavItems(canViewAudit || false);

  const [
    selectedSectionNavItem,
    setSelectedSectionNavItem,
  ] = useState<SectionNavItem>(determineNavItem(pathname, sectionNavItems));

  useEffect(() => {
    const nextNavItem = determineNavItem(pathname, sectionNavItems);
    if (nextNavItem.title === selectedSectionNavItem.title) return; // Prevent infinite loop
    setSelectedSectionNavItem(nextNavItem);
  }, [pathname, sectionNavItems, selectedSectionNavItem]);

  const handleItemSelect = useCallback((item: SectionNavItem) => {
    navigate(item.path);
  }, [navigate]);

  return (
    <SectionNavSelect
      activeItem={selectedSectionNavItem}
      className={styles.select}
      fill
      filterable={false}
      items={sectionNavItems}
      itemRenderer={renderItem}
      onItemSelect={handleItemSelect}
    >
      <Button
        fill
        rightIcon="double-caret-vertical"
        text={selectedSectionNavItem.title}
      />
    </SectionNavSelect>
  );
};
