import React from 'react';
import {
  useLocation,
  useNavigate,
} from 'react-router-dom';

import useCategoryDataByLocation from 'hooks/use-category-data-by-location';
import CategoryNav from 'components/CategoryNav';
import CategoryList from 'components/Category/CategoryList';
import CategoryDetail from 'components/Category/CategoryDetail';
import ErrorBoundary from 'components/ErrorBoundary';
import Spinner from 'components/Spinner';

export default () => {
  const navigate = useNavigate();
  const { search } = useLocation(); // search params like `?projectId=1`
  const { loading, error, categoryByLocation } = useCategoryDataByLocation();

  if (loading) return <Spinner />;
  if (error) throw error;

  const hasChildCategories = categoryByLocation.subcategoryCount > 0;

  const redirectToFirstSubcategory = categoryByLocation.id && categoryByLocation.subcategoryCount > 0;
  if (redirectToFirstSubcategory) navigate(`${categoryByLocation.categories[0].path}${search}`, { replace: true });

  return (
    <>
      <CategoryNav />
      <ErrorBoundary>
        {hasChildCategories ? <CategoryList /> : <CategoryDetail />}
      </ErrorBoundary>
    </>
  );
};
