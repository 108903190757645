import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { NonIdealState } from '@blueprintjs/core';
import _ from 'lodash';

import PartList from 'components/Part/PartList';
import Spinner from 'components/Spinner';
import { determinePlurality } from 'helpers/general';
import {
  STATIC_PARAM_MAP,
  STATIC_PARAM_MAP_INVERTED,
} from 'hooks/use-fuzzy-search';
import { selectInput, selectLoading, selectResults } from 'reducers/search';

import styles from './index.module.css';

export default () => {
  const searchInput = useSelector(selectInput);
  const searchLoading = useSelector(selectLoading);
  const searchResults = useSelector(selectResults);
  const inputEmpty = useMemo(() => {
    return _.reduce(searchInput, (acc, val, key) => {
      if (acc) return acc;
      if (_.isEmpty(val)) return true;
      return false;
    }, false);
  }, [searchInput]);

  if (searchLoading) return <Spinner />;
  if (searchResults.length === 0) {
    return (
      <NonIdealState
        className={styles.empty}
        icon="search"
        title="No results"
        description={inputEmpty ? 'Try creating some items to search' : undefined}
      />
    );
  }

  const searchColumns = _.chain(searchInput)
    .omitBy((v, k) => (
      k === 'categoryNameOrIdentifier' || k === 'limit' || _.isEmpty(v)
    ))
    .keys()
    .map(key => {
      if (key === 'propertyValues') return searchInput[key].map(([k, v]) => k);
      return STATIC_PARAM_MAP[key] ?? key;
    })
    .flatten()
    .value();

  return (
    <div className={styles.container}>
      <PartList
        columns={[
          `${searchResults.length} ${determinePlurality(searchResults.length, 'part')}`,
          'Description',
          'Category',
          ...searchColumns,
        ]}
        rows={searchResults.map(part => ({
          link: `/parts/${part.globalPartNumber}`,
          values: [
            part.globalPartNumber,
            part.shortDescription,
            part.categoryHierarchy,
            ...searchColumns.map(columnName => {
              let columnValue = part[STATIC_PARAM_MAP_INVERTED[columnName]];
              if (_.isNil(columnValue)) {
                columnValue = part.properties.find((p: any) => {
                  return p.categoryProperty.property.name === columnName;
                })?.value;
              }
              return columnValue;
            }),
          ],
        }))}
      />
    </div>
  );
};
