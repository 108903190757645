import React, { useState } from 'react';
import {
  gql,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  Link,
  useNavigate,
  useParams,
} from 'react-router-dom';
import {
  Alert,
  Button, ButtonGroup, Intent, Tag,
} from '@blueprintjs/core';
import classNames from 'classnames';
import _ from 'lodash';

import Spinner from 'components/Spinner';
import Table from 'components/Table';
import { PropertyTypeMap } from 'helpers/general';
import toaster from 'helpers/toaster';
import useCheckPermission from 'hooks/use-check-permission';

import styles from './index.module.css';

const GET_PROPERTY = gql`
  query PropertyById($propertyId: Int!) {
    property: propertyById(propertyId: $propertyId) {
      id
      name
      description
      type
      min
      max
      values
      unit
      categories {
        id
        identifier
        name
        description
        path
        categoryProperties {
          id
          min
          max
          values
          property {
            id
          }
        }
      }
    }
  }
`;

const DELETE_PROPERTY = gql`
  mutation DeleteProperty($propertyId: Int!) {
    propertyRemove(propertyId: $propertyId)
  }
`;

const propertyColumns = [
  'Field',
  'Value',
];
const categoryColumns = [
  'Name',
  'Description',
  'Min',
  'Max',
  'Values',
];

const formatRows = (property: any) => {
  const propertyValueTags = property.values.map((v: string, i: number) => (
    <Tag key={`${property.id}-${i}`}>{v}</Tag>
  ));
  return [
    ['Name', property.name],
    ['Description', property.description],
    ['Type', PropertyTypeMap[property.type]],
    ['Min', property.min],
    ['Max', property.max],
    ['Unit', property.unit],
    ['Values', <div className={styles.values}>{propertyValueTags}</div>],
  ];
};

const formatCategoryRows = (category: any, propertyId: number) => {
  const propertyOverrides = _.find(category.categoryProperties, { property: { id: propertyId } });
  const overrideValueTags = propertyOverrides.values?.map((v: string, i: number) => (
    <Tag key={`${category.id}-${i}`}>{v}</Tag>
  ));

  return [
    [<Link to={`/categories/${category.path}`} key={category.id}>{category.name} ({category.identifier})</Link>],
    [category.description],
    [propertyOverrides.min],
    [propertyOverrides.max],
    [propertyOverrides.values === null ? '' : overrideValueTags],
  ];
};

const rowRenderer = (columns: string[], values: string[], rowIndex: number) => {
  return (
    <tr key={`row-${rowIndex}`}>
      {values.map((value, colIndex) => (
        <td
          key={`row-${rowIndex}-col-${colIndex}`}
          className={classNames(styles.tableCell, styles[`cell${columns[colIndex]}`])}
          title={value}
        >
          {value}
        </td>
      ))}
    </tr>
  );
};

export default () => {
  const [deleteAlertIsOpen, setDeleteAlertIsOpen] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const propertyId = Number(params.propertyId);

  const [canEditProperties] = useCheckPermission('update_properties');
  const [canDeleteProperties] = useCheckPermission('delete_properties');

  const { loading, error, data } = useQuery(GET_PROPERTY, { variables: { propertyId } });
  const [deleteProperty] = useMutation(DELETE_PROPERTY, {
    ignoreResults: true,
    onCompleted: () => {
      toaster.show({
        intent: Intent.SUCCESS,
        message: `Successfully deleted property "${data.property.name}"`,
      });
      navigate('/properties');
    },
    onError: ({ message }) => toaster.show({
      intent: Intent.DANGER,
      message: `Error deleting property: ${message}`,
    }),
  });

  const onConfirmDeleteClick = async () => {
    await deleteProperty({ variables: { propertyId: data.property.id } });
  };

  const renderEditPropertyButton = () => {
    if (!canEditProperties && !canDeleteProperties) return null;

    return (
      <ButtonGroup>
        {canEditProperties && (
          <Button
            icon="edit"
            onClick={() => navigate(`/properties/edit/${propertyId}`)}
          >
            Edit
          </Button>
        )}
        {canDeleteProperties && (
          <>
            <Alert
              isOpen={deleteAlertIsOpen}
              canEscapeKeyCancel
              canOutsideClickCancel
              cancelButtonText="Cancel"
              confirmButtonText="Delete"
              intent={Intent.DANGER}
              icon="trash"
              onConfirm={onConfirmDeleteClick}
              onCancel={() => setDeleteAlertIsOpen(false)}
            >
              Are you sure you want to delete this property? All category assignments
              and their associated part values will also be deleted. This cannot be restored.
            </Alert>
            <Button
              icon="trash"
              onClick={() => setDeleteAlertIsOpen(true)}
            >
              Delete
            </Button>
          </>
        )}
      </ButtonGroup>
    );
  };

  if (loading) return <Spinner />;
  if (error) throw error;

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h1 className={classNames(styles.heading, 'bp4-heading')}>{data.property.name}</h1>
        {renderEditPropertyButton()}
      </header>

      <Table
        classNames={[styles.table]}
        columns={['Field', 'Value']}
        rows={formatRows(data.property)}
        rowRenderer={(values: string[], rowIndex: number) => rowRenderer(propertyColumns, values, rowIndex)}
      />

      <h2 className={classNames('bp4-heading', styles.categoryHeading)}>Category Assignments</h2>
      <Table
        classNames={[styles.table]}
        columns={categoryColumns}
        rows={data.property.categories.map((c: any) => {
          return formatCategoryRows(c, propertyId);
        })}
        rowRenderer={(values: string[], rowIndex: number) => rowRenderer(categoryColumns, values, rowIndex)}
      />
    </div>
  );
};
