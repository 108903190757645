import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Alert, Button, Intent, Menu, MenuDivider, MenuItem, SpinnerSize, Switch } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { gql, useMutation, useQuery } from '@apollo/client';

import { selectProjectView, uiSlice } from 'reducers/ui';
import useCheckPermission from 'hooks/use-check-permission';
import toaster from 'helpers/toaster';
import Spinner from 'components/Spinner';
import { CATEGORY_ROOT_PATH_REGEXP } from '../../constants';

import styles from './index.module.css';

const GET_PARENT_CATEGORY = gql`
  query ParentCategoryById($categoryId: Int!) {
    category: categoryById(categoryId: $categoryId) {
      id
      name
      partCount
      parentCategory {
        id
        name
        path
      }
    }
  }
`;

const DELETE_CATEGORY = gql`
  mutation DeleteCategory($categoryId: Int!) {
    categoryRemove(categoryId: $categoryId)
  }
`;

interface Props {
  isRootCategory: boolean;
  categoryId: number | undefined;
  isLeafCategory: boolean;
}

export default function NavMenu(props: Props) {
  const [deleteAlertIsOpen, setDeleteAlertIsOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const projectView: boolean = useSelector(selectProjectView);

  const { loading, error, data } = useQuery(GET_PARENT_CATEGORY, {
    variables: {
      categoryId: props.categoryId,
    },
    skip: !props.categoryId,
  });

  const [deleteCategory] = useMutation(DELETE_CATEGORY, {
    ignoreResults: true,
    onCompleted: () => {
      toaster.show({
        intent: Intent.SUCCESS,
        message: `Successfully deleted category "${data.category.name}"`,
      });
      navigate(`/categories/${data.category.parentCategory.path || ''}`);
    },
    onError: ({ message }) => toaster.show({
      intent: Intent.DANGER,
      message: `Error deleting category: ${message}`,
    }),
  });

  const [canAddParts] = useCheckPermission('create_parts');
  const [canEditCategories] = useCheckPermission('update_categories');
  const [canAddCategories] = useCheckPermission('create_categories');
  const [canViewProjects] = useCheckPermission('get_projects');
  const [canDeleteCategories] = useCheckPermission('delete_categories');

  if (loading) return <Spinner size={SpinnerSize.SMALL} />;
  if (error) throw error;

  const path = pathname.replace(CATEGORY_ROOT_PATH_REGEXP, '');

  const onConfirmDeleteClick = async () => {
    await deleteCategory({ variables: { categoryId: data.category.id } });
  };

  const renderAddPartButton = () => {
    if (!canAddParts || !props.isLeafCategory) return null;

    const projectId = searchParams.get('projectId');

    const determineNavigateDestination = () => {
      const params = new URLSearchParams();
      if (props.categoryId) params.append('categoryId', String(props.categoryId));
      if (projectId) params.append('projectId', projectId);
      return `/parts/create?${params.toString()}`;
    };

    return (
      <MenuItem
        text="Add Part"
        onClick={() => navigate(determineNavigateDestination())}
      />
    );
  };

  const renderEditCategoryButton = () => {
    if (canEditCategories && !props.isRootCategory) {
      return (
        <MenuItem
          text={`Edit ${data?.category.name} Category`}
          onClick={() => {
            navigate(`/categories/edit/${path}`);
          }}
        />
      );
    }

    return null;
  };

  const renderAddCategoryButton = () => {
    if (!canAddCategories) return null;

    let menuText;

    if (props.isRootCategory) menuText = 'Add Root Category';
    // Handle case where root category doesn't have any subcategories yet.
    else if (data?.category.parentCategory === null) menuText = `Add ${data?.category.name} Subcategory`;
    else menuText = `Add ${data?.category.parentCategory.name} Subcategory`;

    let parentCategoryId = data?.category?.parentCategory?.id as number;
    if (data?.category.parentCategory === null) parentCategoryId = data?.category.id;

    return (
      <MenuItem
        text={menuText}
        onClick={() => {
          let dest = '/categories/create';
          if (props.categoryId) dest += `?parentCategoryId=${parentCategoryId}`;
          navigate(dest);
        }}
      />
    );
  };

  const renderViewByProjectSwitch = () => {
    if (!canViewProjects) return null;

    return (
      <MenuItem
        labelClassName={styles.switchMenuItemLabel}
        textClassName={styles.switchMenuItem}
        shouldDismissPopover={false}
        text={(
          <Switch
            alignIndicator="right"
            defaultChecked={projectView}
            className={styles.switch}
            label="View by Project"
            onChange={() => {
              dispatch(uiSlice.actions.toggleProjectView());
              navigate('/categories');
            }}
          />
        )}
      />
    );
  };

  const renderDeleteCategoryButton = () => {
    if (!canDeleteCategories || data?.category.partCount > 0) return null;

    return (
      <MenuItem
        text="Delete Category"
        onClick={() => setDeleteAlertIsOpen(true)}
      />
    );
  };

  return (
    <>
      <Alert
        isOpen={deleteAlertIsOpen}
        canEscapeKeyCancel
        canOutsideClickCancel
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        intent={Intent.DANGER}
        icon="trash"
        onConfirm={onConfirmDeleteClick}
        onCancel={() => setDeleteAlertIsOpen(false)}
      >
        Are you sure you want to delete this category? Once deleted, this
        cannot be restored.
      </Alert>
      <Popover2
        position="bottom"
        content={(
          <Menu>
            {renderViewByProjectSwitch()}
            {renderAddPartButton()}
            {(!projectView || path) && (
              <>
                <MenuDivider />
                {renderEditCategoryButton()}
                {renderAddCategoryButton()}
                {renderDeleteCategoryButton()}
              </>
            )}
          </Menu>
        )}
      >
        <Button icon="chevron-down" small />
      </Popover2>
    </>
  );
}
