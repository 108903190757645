import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Button,
  FormGroup,
  Icon,
  InputGroup,
  Intent,
  MenuItem,
  NumericInput,
  PopoverPosition,
  Tooltip,
} from '@blueprintjs/core';
import { ItemRenderer, Select } from '@blueprintjs/select';

import toaster from 'helpers/toaster';

import styles from './index.module.css';

const GET_SIBLING_PARTS = gql`
  query GetSiblingParts($categoryId: Int!, $localPartNumber: String!) {
    parts: partsByLocalPartNumber(categoryId: $categoryId, localPartNumber: $localPartNumber) {
      id
      properties {
        value
        categoryProperty {
          sizeGraded
        }
      }
    }
  }
`;

const ADD_PROPERTY_VALUE = gql`
  mutation AddPropertyValue($propertyId: Int!, $value: String!) {
    propertyAddValue(propertyId: $propertyId, value: $value) {
      id
    }
  }
`;

const ADD_CATEGORY_PROPERTY_VALUE = gql`
  mutation AddCategoryPropertyValue($categoryPropertyId: Int!, $value: String!) {
    categoryPropertyAddValue(categoryPropertyId: $categoryPropertyId, value: $value) {
      id
    }
  }
`;

interface Props {
  categoryId: number | null;
  partId?: number;
  partNumber: string;
  fields: any;
}

interface Item {
  label?: string;
  value: string;
  new?: boolean;
}

const ItemSelect = Select.ofType<Item>();

const renderPropertyOverrideTooltip = () => (
  <Tooltip
    position={PopoverPosition.RIGHT}
    content="Using category property override values"
    className={styles.propertyOverrideInfo}
  >
    <Icon icon="info-sign" />
  </Tooltip>
);

export default (props: Props) => {
  const { control } = useFormContext();

  const [unavailableSizeGrades, setUnavailableSizeGrades] = useState<string[]>([]);
  const {
    data: siblingPartData,
  } = useQuery(GET_SIBLING_PARTS, {
    variables: {
      categoryId: props.categoryId,
      localPartNumber: props.partNumber,
    },
    skip: !props.categoryId || !/^\d{3}$/.test(props.partNumber),
  });
  useEffect(() => {
    if (siblingPartData) {
      const sizeGradeValues = siblingPartData.parts.map((part: any) => {
        return part.id !== props.partId && part.properties.find((p: any) => p.categoryProperty.sizeGraded)?.value;
      }) ?? [];
      setUnavailableSizeGrades(sizeGradeValues);
    }
  }, [props.partId, siblingPartData, setUnavailableSizeGrades]);

  const [addPropertyValue] = useMutation(ADD_PROPERTY_VALUE, {
    onCompleted: () => toaster.show({
      intent: Intent.SUCCESS,
      message: 'Property value added successfully',
    }),
    onError: ({ message }) => toaster.show({
      intent: Intent.DANGER,
      message: `Error adding value: ${message}`,
    }),
  });

  const [addCategoryPropertyValue] = useMutation(ADD_CATEGORY_PROPERTY_VALUE, {
    onCompleted: () => toaster.show({
      intent: Intent.SUCCESS,
      message: 'Override value added successfully',
    }),
    onError: ({ message }) => toaster.show({
      intent: Intent.DANGER,
      message: `Error adding value: ${message}`,
    }),
  });

  const createNewPropertyValue = async (value: string, property: any) => {
    if (property.usingOverrides > 0) {
      await addCategoryPropertyValue({
        variables: {
          categoryPropertyId: property.categoryPropertyId,
          value,
        },
      });
    } else {
      await addPropertyValue({
        variables: {
          propertyId: property.propertyId,
          value,
        },
      });
    }
  };

  const [items, setItems] = useState<Item[][]>([]);
  useEffect(() => {
    setItems(props.fields.map((p: any) => {
      if (!p.required) return [{ label: 'N/A', value: '' }, ...p.values];
      return p.values;
    }));
  }, [props.fields, setItems]);

  const renderTextInputs = (property: any, field: any) => {
    if (property.type === 'STRING') {
      return (
        <InputGroup
          defaultValue={field.value}
          onChange={event => field.onChange(event.target.value)}
        />
      );
    }

    return (
      <NumericInput
        defaultValue={field.value === null ? '' : field.value}
        min={property.min}
        max={property.max}
        onValueChange={(numVal, strVal) => field.onChange(strVal)}
      />
    );
  };

  return props.fields.map((property: any, index: number) => {
    const itemRenderer: ItemRenderer<Item> = (item, { modifiers, handleClick }) => {
      if (!modifiers.matchesPredicate) return null;

      const sizeGradeIdentifier = property.sizeGrades?.find((s: any) => s.value === item.value)?.identifier;
      let itemText = item.label ?? item.value;
      if (sizeGradeIdentifier) itemText += ` (${sizeGradeIdentifier})`;
      if (item.new) {
        itemText = `Create new value '${item.value}'`;
      }

      return (
        <MenuItem
          disabled={property.sizeGraded && unavailableSizeGrades.includes(item.value)}
          icon={item.new ? 'add' : undefined}
          key={`${property.id}-${item.value}`}
          onClick={handleClick}
          selected={modifiers.active}
          text={itemText}
        />
      );
    };
    const onQueryChange = (query: string) => {
      const hasMatchingItem = items[index].some(i => {
        return !i.new && i.value.includes(query);
      });
      const newItem = items[index].find(i => i.new);

      const updatedItems = [...items];
      if (hasMatchingItem && newItem) {
        updatedItems[index] = updatedItems[index].filter(i => !i.new);
        setItems(updatedItems);
      } else if (!hasMatchingItem && !newItem) {
        updatedItems[index].push({ value: query, new: true });
        setItems(updatedItems);
      } else if (!hasMatchingItem && newItem) {
        const newIndex = updatedItems[index].findIndex(i => i.new);
        updatedItems[index][newIndex] = {
          value: query,
          new: true,
        };
        setItems(updatedItems);
      }
    };

    return (
      <div
        className={styles.propertyContainer}
        key={property.id}
      >
        <Controller
          name={`properties.${index}.value`}
          control={control}
          render={({ field }) => {
            const sizeGradeIdentifier = property.sizeGrades?.find((s: any) => s.value === field.value)?.identifier;
            let label = field.value ?? 'N/A';
            if (sizeGradeIdentifier) label += ` (${sizeGradeIdentifier})`;

            return (
              <FormGroup
                className={styles.propertyFormGroup}
                inline
                label={(
                  <div className={styles.propertyLabel}>
                    <span>{property.label}{property.sizeGraded && <b> - Size Graded</b>}</span>
                    {property.usingOverrides && renderPropertyOverrideTooltip()}
                  </div>
                )}
              >
                {property.values.length === 0
                  ? renderTextInputs(property, field)
                  : (
                    <ItemSelect
                      filterable
                      itemPredicate={(q, item) => (item.label ?? item.value).includes(q)}
                      itemRenderer={itemRenderer}
                      items={items[index] ?? []}
                      onItemSelect={async item => {
                        if (item.new) {
                          await createNewPropertyValue(item.value, property);
                        }
                        field.onChange(item.value);
                      }}
                      onQueryChange={onQueryChange}
                      resetOnClose
                      resetOnSelect
                      resetOnQuery
                    >
                      <Button
                        rightIcon="double-caret-vertical"
                        text={label}
                      />
                    </ItemSelect>
                  )
                }
              </FormGroup>
            );
          }}
        />
      </div>
    );
  });
};
